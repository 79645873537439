import { Button } from 'reactstrap';
import React, {Component} from 'react';
import ReactExport from 'react-data-export';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const multiDataSet = [
    {
        columns: [
            {title: "Headings", width: {wch: 40},style: {font: {sz: "18", bold: true,italic: true},fill: {patternType: "solid", fgColor: {rgb: "FFCCEEFF"}}}},//pixels width 
            {title: "Text Style", width: {wch: 40},style: {font: {sz: "18", bold: true,italic: true},fill: {patternType: "solid", fgColor: {rgb: "FFCCEEFF"}}}},//char width 
            {title: "Colors", width: {wch: 40},style: {font: {sz: "18", bold: true,italic: true},fill: {patternType: "solid", fgColor: {rgb: "FFCCEEFF"}}}},
        ],
        data: [
            [
                {value: "H1", style: {font: {sz: "24", bold: true}}},
                {value: "Bold", style: {font: {bold: true}}},
                {value: "Red", style: {fill: {patternType: "solid", fgColor: {rgb: "FFFF0000"}}}},
            ],
            [
                {value: "H2", style: {font: {sz: "18", bold: true}}},
                {value: "underline", style: {font: {underline: true}}},
                {value: "Blue", style: {fill: {patternType: "solid", fgColor: {rgb: "FF0000FF"}}}},
            ],
            [
                {value: "H3", style: {font: {sz: "14", bold: true}}},
                {value: "italic", style: {font: {italic: true}}},
                {value: "Green", style: {fill: {patternType: "solid", fgColor: {rgb: "FF00FF00"}}}},
            ],
            [
                {value: "H4", style: {font: {sz: "12", bold: true}}},
                {value: "strike", style: {font: {strike: true}}},
                {value: "Orange", style: {fill: {patternType: "solid", fgColor: {rgb: "FFF86B00"}}}},
            ],
            [
                {value: "H5", style: {font: {sz: "10.5", bold: true}}},
                {value: "outline", style: {font: {outline: true}}},
                {value: "Yellow", style: {fill: {patternType: "solid", fgColor: {rgb: "FFFFFF00"}}}},
            ],
            [
                {value: "H6", style: {font: {sz: "7.5", bold: true}}},
                {value: "shadow", style: {font: {shadow: true}}},
                {value: "Light Blue", style: {fill: {patternType: "solid", fgColor: {rgb: "FFCCEEFF"}}}}
            ]
        ]
    }
];


const createMultiData=()=>{
    let defaultColumnStyle= {
        title: "Headings",
        width: {wch: 40},
        style: {
            font: {sz: "18", bold: true,italic: true},
            fill: {patternType: "solid", fgColor: {rgb: "FFCCEEFF"}}
        }};

}
const defaultWidth={wch: 40};
const defaultStyle={
    font: {sz: "18", bold: true,italic: true},
    fill: {patternType: "solid", fgColor: {rgb: "FFCCEEFF"}}
};
const findColumns=(inputData)=>{
    let firstElement=inputData[0];
    let columnKeys=Object.keys(firstElement);
    let columnCsv=[];
    columnKeys.forEach((value) =>{ columnCsv.push({
        title: value,
        width:defaultWidth,
        style:defaultStyle,
        value:'category_name'
     });
   })
   return columnCsv;
}

const findRows=(inputData,columnKeys)=>{
    let rowCsv=[];
    inputData.map((itemObject)=>
    {   let rowArray=[];
        columnKeys.forEach((value) =>{
        let cell={};
        if(typeof(itemObject[value])!='object'){
        if((itemObject[value]===0)||(itemObject[value])){
        cell.value=itemObject[value];
        }
        else{
            cell.value="";
        }
    }
        rowArray.push(cell)
        });
        rowCsv.push(rowArray);

    });
    return rowCsv;

}
class ExportReactCSV extends Component {
 csvDataSet=[];
    render() {
        if(typeof(this.props.csvData[0])!='undefined')
        {  let columnCsv=(typeof(this.props.columnCsv)==='undefined')?findColumns(this.props.csvData):this.props.columnCsv;
           let columnKeys=(typeof(this.props.columnKeys)==='undefined')?Object.keys(this.props.csvData[0]):this.props.columnKeys;
           let rowCsv=(typeof(this.props.rowCsv)==='undefined')?findRows(this.props.csvData,columnKeys):this.props.rowCsv;
           this.csvDataSet=[{columns:columnCsv,data:rowCsv}];

          console.log(this.csvDataSet);
           
        }
        return(
            <div>
                <ExcelFile element={<Button className="float-right" size={'sm'} color="primary">
                    <i className='uil uil-export ml-1'></i> Export As Excel
                </Button>
            }>
                    <ExcelSheet dataSet={this.csvDataSet} name="Organization"/>
                </ExcelFile>
            </div>
        );
    }
}
export default  ExportReactCSV