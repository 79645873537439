export function configureFakeBackend() {
    let users = [{ id: 1, username: 'test', password: 'test', firstName: 'Test', lastName: 'User', role: 'Admin' }];
    let realFetch = window.fetch;
    window.fetch = function(url, opts) {
        return new Promise((resolve, reject) => {
            // wrap in timeout to simulate server api call
            setTimeout(() => {
                // authenticate
                if (url.endsWith('/users/authenticate') && opts.method === 'POST') {
                    // get parameters from post request
                    authoniticate();
                }

                // register
                if (url.endsWith('/users/register') && opts.method === 'POST') {

                    register();
                }

                // forget password
                if (url.endsWith('/users/password-reset') && opts.method === 'POST') {

                    passwordRest();
                }

                // get users
                if (url.endsWith('/users') && opts.method === 'GET') {

                    getUsers();
                }

                // pass through any requests not handled above
                realFetch(url, opts).then(response => resolve(response));
            }, 500);
            //authonticattion
            function authoniticate()
            {
                let params = JSON.parse(opts.body);

                // find if any user matches login credentials
                let filteredUsers = users.filter(user => {
                    return user.username === params.username && user.password === params.password;
                });

                if (filteredUsers.length) {
                    // if login details are valid return user details and fake jwt token
                    let user = filteredUsers[0];
                    let responseJson = {
                        id: user.id,
                        username: user.username,
                        firstName: user.firstName,
                        lastName: user.lastName,
                        role: 'Admin',
                        token:
                            'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJjb2RlcnRoZW1lcyIsImlhdCI6MTU4NzM1NjY0OSwiZXhwIjoxOTAyODg5NDQ5LCJhdWQiOiJjb2RlcnRoZW1lcy5jb20iLCJzdWIiOiJzdXBwb3J0QGNvZGVydGhlbWVzLmNvbSIsImxhc3ROYW1lIjoiVGVzdCIsIkVtYWlsIjoic3VwcG9ydEBjb2RlcnRoZW1lcy5jb20iLCJSb2xlIjoiQWRtaW4iLCJmaXJzdE5hbWUiOiJTaHJleXUifQ.D-isMYoGH6Ah4i_dHxplgJNGtXTLEqZYvha_ULSJRFU',
                    };
                    resolve({ ok: true, json: () => responseJson });
                } else {
                    // else return error
                    reject('Username or password is incorrect');
                }
                return;
            }
            //register function
            function register()
            {
                 // get parameters from post request
                 let params = JSON.parse(opts.body);

                 // add new users
                 let { firstName, lastName } = params.fullname.split(' ');
                 let newUser = {
                     id: users.length + 1,
                     username: firstName,
                     password: params.password,
                     firstName: firstName,
                     lastName: lastName,
                     role: 'Admin',
                 };
                 users.push({ newUser });

                 let responseJson = {
                     id: newUser.id,
                     username: newUser.username,
                     firstName: newUser.firstName,
                     lastName: newUser.lastName,
                     role: newUser.role,
                     token:
                         'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJjb2RlcnRoZW1lcyIsImlhdCI6MTU4NzM1NjY0OSwiZXhwIjoxOTAyODg5NDQ5LCJhdWQiOiJjb2RlcnRoZW1lcy5jb20iLCJzdWIiOiJzdXBwb3J0QGNvZGVydGhlbWVzLmNvbSIsImxhc3ROYW1lIjoiVGVzdCIsIkVtYWlsIjoic3VwcG9ydEBjb2RlcnRoZW1lcy5jb20iLCJSb2xlIjoiQWRtaW4iLCJmaXJzdE5hbWUiOiJTaHJleXUifQ.D-isMYoGH6Ah4i_dHxplgJNGtXTLEqZYvha_ULSJRFU',
                 };
                 resolve({ ok: true, json: () => responseJson });
                 return;
            }
            //passwword reset
            function passwordRest()
            {
                  // get parameters from post request
                  let params = JSON.parse(opts.body);

                  // find if any user matches login credentials
                  let filteredUsers = users.filter(user => {
                      return user.username === params.username;
                  });

                  if (filteredUsers.length) {
                      let responseJson = {
                          message: "We've sent you a link to reset password to your registered email.",
                      };
                      resolve({ ok: true, json: () => responseJson });
                  } else {
                      // else return error
                      reject('Sorry, we could not find any registered user with entered username');
                  }
                  return;
            }
            //get user function
            function getUsers()
            {
                // check for fake auth token in header and return users if valid, this security is implemented server side in a real application
                if (
                    opts.headers &&
                    opts.headers.Authorization ===
                        'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJjb2RlcnRoZW1lcyIsImlhdCI6MTU4NzM1NjY0OSwiZXhwIjoxOTAyODg5NDQ5LCJhdWQiOiJjb2RlcnRoZW1lcy5jb20iLCJzdWIiOiJzdXBwb3J0QGNvZGVydGhlbWVzLmNvbSIsImxhc3ROYW1lIjoiVGVzdCIsIkVtYWlsIjoic3VwcG9ydEBjb2RlcnRoZW1lcy5jb20iLCJSb2xlIjoiQWRtaW4iLCJmaXJzdE5hbWUiOiJTaHJleXUifQ.D-isMYoGH6Ah4i_dHxplgJNGtXTLEqZYvha_ULSJRFU'
                ) {
                    resolve({ ok: true, json: () => users });
                } else {
                    // return 401 not authorised if token is null or invalid
                    reject('Unauthorised');
                }

                return;
            }
        });
    };
}
