import React, { Component, useState } from 'react';
import FileUpload from '../../Return/FileUpload';
import {
    Row,
    Col,
    UncontrolledCollapse,
    Card,
    CardBody,
    CustomInput,
    FormGroup,
    Form,

    Button,
    Label,
    Input,
    Badge,
} from 'reactstrap';
import Select from 'react-select';
import RITNCheckListService from '../../../../services/RITNCheckListService';
import {
    alertCreation,
    alertUpload,
    alertDeletion,
    alertUpdation,
    alertError,
    confirmDeleteAction,
    confirmAction,
} from '../../../../components/Alerts';

const TaskCard = (props) => {
  const ALL=0,NOTE=1,TASK=2,TASK_PENDING=3,TASK_CLOSED=4;





    const setNoteFilter=(filterValue)=>
    {
      props.setNoteFilter(filterValue);
    }

    const toggleFollowUpForm=()=>
    {
      props.setEditFormVisiblity(!props.editFormVisiblity);
    }

    const checkListStatusHandler = (event) => {
        if (event.target.type == 'checkbox') {
            let key = event.target.name;
            let value = event.target.checked;
            props.updateCheckListStatus(props.checkListObj.id, value);
        }
    };

    let departmentData = [];
    let task_status_id = 'task_status_id';
    let enableTaskId = 'taskEnable';
    let taskStatusID = 'taskStatus';
    let editCardId="editCard";
    if (typeof props.checkListObj != 'undefined') {
        departmentData = props.checkListObj.check_list.assigned_roles.map((depItem, idx2) => ({
            label: depItem.name,
            value: depItem.id,
        }));
        task_status_id = task_status_id + props.checkListObj.id;
        enableTaskId = enableTaskId + props.checkListObj.id;
        taskStatusID = taskStatusID + props.checkListObj.id;
        editCardId=editCardId+props.checkListObj.id;
        

        return (
            <div>
                {props.checkListObj.check_list['list_description']}
                {
                (props.checkListObj.check_list.cheklist_type!=7)&& <FormGroup>
                    <Label for="inovice date">{props.taskPendingCount} Task Pending
                    
                    </Label>

                    <CustomInput
                        type="switch"
                        id={task_status_id}
                        name="status"
                        onChange={checkListStatusHandler}
                        checked={props.checkListObj.status}
                        disabled={((props.checkListObj.status))}
                    />
                </FormGroup>}
                {/*
                
             {(props.checkListObj.status==false)&&   <Button outline color="primary" size="sm" 
                
                onClick={() => {
                  toggleFollowUpForm();
              }}
                >
                    New Follow-Up
                </Button>}
                {' '}
<Button  color="primary" size="sm"
     onClick={() => {
      setNoteFilter(ALL);
  }}

>
  All Follow-Ups<span class="badge badge-light">{props.allCount}</span>
</Button>
              
                {' '}
                <Button   size="sm"  color="primary"
                
                onClick={() => {
                  setNoteFilter(TASK);
              }}
                >
                  
  {props.taskCount}-All Tasks
</Button>{' '}
<Button   size="sm"  color="primary"
                
                onClick={() => {
                  setNoteFilter(TASK_PENDING);
              }}
                >
                  
  <span class="badge badge-danger">Task Pending({props.taskPendingCount})</span>
</Button>{' '}
<Button   size="sm"  color="primary"
                
                onClick={() => {
                  setNoteFilter(TASK_CLOSED);
              }}
                >
                  
  <span class="badge badge-success">Task Closed({props.taskClosedCount})</span>
</Button>{' '}

<Button  color="primary" size="sm" 
 onClick={() => {
  setNoteFilter(NOTE);
}}

>
  Notes<span class="badge badge-light">{props.noteCount}</span>
</Button>
{(props.checkListObj.status==false)&&
<div id={editCardId} hidden={props.editFormVisiblity}>                  <Card>
                        <CardBody>
                            <NoteForm
                                addNote={props.addNote}
                                departmentData={departmentData}
                                enableTaskId={enableTaskId}
                                taskStatusID={taskStatusID}
                                staffData={props.staffData}

                            />
                        </CardBody>
                    </Card>
               </div>}

*/}

            </div>


        );
    } else {
        return '';
    }
};

const NoteForm = (props) => {
    const [noteCreateForm, setNoteCreateForm] = useState({});
    const [taskCreateForm, setTaskCreateForm] = useState({});
    const [isEnableTask, setIsEnableTask] = useState(false);
    const [isTaskClosed,setIsTaskClosed]=useState(false);
    const [taskChoices, setTaskChoices] = useState([
        { label: 'LOW', value: 1 },
        { label: 'NORMAL', value: 10 },
        { label: 'HIGH', value: 20 },
    ]);

    const isPendingTaskStatusHandler = (event) => {
      if (event.target.type == 'checkbox') {
          let key = event.target.name;
          let value = event.target.checked;
          setIsTaskClosed(value);
          let inputForm = { ...taskCreateForm };
          inputForm['is_task_closed'] = value;
          setTaskCreateForm(inputForm);
      }
  }; 
    const isEnableStatusHandler = (event) => {
        console.log('called');
        if (event.target.type == 'checkbox') {
            let key = event.target.name;
            let value = event.target.checked;
            console.log(value);
            setIsEnableTask(value);
            let inputForm = { ...noteCreateForm };
            inputForm['is_task'] = value;
            setNoteCreateForm(inputForm);
        }
    };

    const handleStaffSelectChange = (selectedOption) => {
        let inputForm = { ...taskCreateForm };

        if (selectedOption) {
            let staffSelected = selectedOption.value;

            inputForm['assigned_staffs'] = staffSelected;
        }
        setTaskCreateForm(inputForm);
    }; 

    const handleTaskSelectChange = (selectedOption) => {
        let inputForm = { ...taskCreateForm };

        if (selectedOption) {
            let taskPrioritySelected = selectedOption.value;

            inputForm['task_priorities'] = taskPrioritySelected;
        }
        setTaskCreateForm(inputForm);
    };
    const handleDepartmentSelectChange = (selectedOption) => {
        let inputForm = { ...taskCreateForm };

        if (selectedOption) {
            let departmentSelected = selectedOption.map((obj) => obj.value);

            inputForm['assigned_departments'] = departmentSelected;
        } else {
            inputForm['assigned_departments'] = [];
        }
        setTaskCreateForm(inputForm);
    };

    const handleFiles = (fileList) => {
        let inputForm = { ...noteCreateForm };
        if (fileList) {
            inputForm['note_images'] = fileList;
            setNoteCreateForm(inputForm);
        }
        console.log(noteCreateForm);
    };

    const createFormHandler = (event) => {
        let key = '';
        let value = '';

        let inputForm = { ...noteCreateForm };

        key = event.target.name;
        value = event.target.value;
        inputForm[key] = value;
        setNoteCreateForm(inputForm);
    };
    const addNote = () => {
        let inputForm = { ...noteCreateForm };
        let taskInputForm = { ...taskCreateForm };
        console.log('input',inputForm);
        console.log('task',taskInputForm);
        if (isEnableTask) {

          for (let key in taskInputForm) {
              
            inputForm[key] = taskInputForm[key]
          }
          }
        
          setNoteCreateForm({title:"",description:"",});
          setTaskCreateForm({});
        props.addNote(inputForm);
     

    };

    return (
        <React.Fragment>
            <FormGroup>
                <Label for="Note">Tittle</Label>
                <Input
                    type="text"
                    name="title"
                    id="Notetitle"
                    value={noteCreateForm['title']}
                    placeholder="Write your Note"
                    onChange={createFormHandler}
                />
            </FormGroup>
            <FormGroup>
                <Label for="Note">Comment</Label>
                <Input
                    type="textarea"
                    name="description"
                    id="Notet"
                    value={noteCreateForm['description']}

                    placeholder="Write your Note"
                    onChange={createFormHandler}
                />
            </FormGroup>
            <FormGroup>
                <FileUpload handleFiles={handleFiles} />
            </FormGroup>
            <FormGroup>
                <Label for="inovice date">Enable Task</Label>

                <CustomInput type="switch" id={props.enableTaskId}
                checked={isEnableTask}
                name="is_task" onChange={isEnableStatusHandler} />
            </FormGroup>
            <div hidden={!isEnableTask}>

            <FormGroup>
                    <Label for="Note">Assigned To</Label>

                    <Select
                        name="assigned_staffs"
                        className="react-select"
                        isMulti={false}
                        classNamePrefix="react-select"
                        onChange={handleStaffSelectChange}
                        options={props.staffData}></Select>
                </FormGroup>

                <FormGroup>
                    <Label for="Note">Assigned Departments</Label>

                    <Select
                        name="department"
                        className="react-select"
                        isMulti={true}
                        classNamePrefix="react-select"
                        onChange={handleDepartmentSelectChange}
                        options={props.departmentData}></Select>
                </FormGroup>
                <FormGroup>
                    <Label for="priority">Task Priority</Label>

                    <Select
                        name="task_choices"
                        className="react-select"
                        isMulti={false}
                        classNamePrefix="react-select"
                        onChange={handleTaskSelectChange}
                        options={taskChoices}></Select>
                </FormGroup>
                <FormGroup>
                    <Label for="inovice date">Task Status : Pending/Closed</Label>

                    <CustomInput type="switch" id={props.taskStatusID} name="task_status"
                    
                    onChange={isPendingTaskStatusHandler}
                    />
                </FormGroup>
            </div>
            <FormGroup>
                <Button color="primary" type="submit" onClick={() => {addNote();
                
                
                }}>
                    Submit
                </Button>
            </FormGroup>
        </React.Fragment>
    );
};

/*
fetch elements from server and reload data elements
*/

export default TaskCard;
