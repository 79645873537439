import React, { Component, useState } from 'react';
import { Row, Col, Card, CardBody, CustomInput, UncontrolledTooltip, Form, FormGroup, Button, Label, Input, Badge } from 'reactstrap';
import PageTitle from '../../../components/PageTitle';
import FileUpload from './FileUpload';
import AddReturn from './AddReturn';
import {
    alertCreation,
    alertUpload,
    alertDeletion,
    alertUpdation,
    alertError,
    confirmDeleteAction,
    confirmAction,
} from '../../../components/Alerts';
import { getLoggedInUser } from "../../../helpers/authUtils";

import RITNService from '../../../services/RITNService';
import ProductPartService from '../../../services/ProductPartService';
import ProductService from '../../../services/ProductService';
import CustomerService from '../../../services/CustomerService';
import { getCurrentDate, convertDate } from '../../../services/DataFormatHandler';
import CheckListCard from '../Return/Detail/CheckListCard'
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import StaffService from '../../../services/StaffService';
import { Redirect, Link } from 'react-router-dom'
import { conclusionData, currentURL } from '../../../helpers/globalConstants'
import { returnThroughOptions, customerTypes } from '../../../helpers/globalConstants';
import ExportReactCSV from '../../../components/ExcelExport';
import jsPDF from "jspdf";
import "jspdf-autotable";
import Moment from 'moment';

import { isPermitted } from '../../../helpers/permission-manager';
import Flatpickr from 'react-flatpickr'

const CREATE = 0, DESTROY = 1, UPDATE = 2;













const RITNInputElements = (props) => {


    let defaultCustomerValue = 0, defaultConclusion = 0;
    if (typeof (props.createForm['customer'])) {

        defaultCustomerValue = props.customerData.filter((cobj => cobj.value == Number(props.createForm['customer'])))


    }

    if (typeof (props.createForm['conclusion'])) {
        console.log("Conclusion");
        defaultConclusion = props.conclusionData.filter((cobj => cobj.value == Number(props.createForm['conclusion'])))
        console.log("Conclusion", defaultConclusion);


    }
    let defaultReturnThrough = { value: 'Courier', label: 'Courier' };

    if (typeof (props.createForm['return_through'])) {
        returnThroughOptions.filter((cobj => cobj.value == props.createForm['return_through']))
    }

    console.log(props.createForm)

    return (
        <Card>
            <CardBody>
                <h4 className="header-title mt-0">
                    RITN NUMBER{' '}
                    <Badge color="success" pill className="" id="12012">
                        {props.currentRITNObject.ritn_number}
                    </Badge>
                </h4>
                <p className="text-muted">Service/Sales Return Part Inspection Registartion Form</p>

                <Row>
                    <Col lg={6}>
                        <FormGroup>
                            <Label for="invoice number">RITN Number</Label>
                            <Input
                                type="text"
                                name="ritn_number"
                                id="ritn_number"
                                onChange={props.createFormHandler}
                                placeholder="Invoice /Debit note Number"
                                value={props.currentRITNObject.ritn_number}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="Customer">Consigner</Label>

                            <Select

                                onChange={props.handleChange}
                                isDisabled={!isPermitted("edit_part_recieved_date")}

                                name="customer"
                                className="react-select"
                                classNamePrefix="react-select"
                                options={props.customerData}
                                value={defaultCustomerValue}


                            />

                        </FormGroup>
                        <FormGroup>
                            <Label for="customer_type">Consigner Type</Label>
                            <Input
                                type="text"
                                name="customer_type"
                                id="customer_type"
                                readOnly={true}
                                value={props.createForm['customer_type']}
                            />


                        </FormGroup>


                        <FormGroup>
                            <Label for="invoice number">Phone</Label>
                            <Input
                                type="text"
                                name="phone"
                                readOnly={true}
                                id="phone"
                                value={props.createForm['phone']}
                                placeholder="Mobile Number"

                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for="invoice number">State</Label>
                            <Input
                                type="text"
                                name="state"
                                id="state"
                                readOnly={true}

                                value={props.createForm['state']}

                            />

                        </FormGroup>




                        <FormGroup>
                            <Label for="inovice date">Recieved with proper Documents</Label>
                            <CustomInput
                                type="switch"
                                id="recieved_with_proper_doc"
                                disabled={(!isPermitted('edit_part_recieved_date'))}
                                name="recieved_with_proper_doc"
                                checked={props.createForm['recieved_with_proper_doc']}
                                onChange={props.createFormHandler}
                            />
                            {((!props.createForm['recieved_with_proper_doc']) || (props.createForm['recieved_with_proper_doc'] == false)) && "NO"}

                            {((props.createForm['recieved_with_proper_doc']) && (props.createForm['recieved_with_proper_doc'] == true)) && "YES"}

                        </FormGroup>

                        {((props.createForm['recieved_with_proper_doc']) && (props.createForm['recieved_with_proper_doc'] == true)) &&
                            <FormGroup>
                                <Label for="invoice number"> Document Number</Label>
                                <Input
                                    type="text"
                                    readOnly={(!isPermitted('edit_part_recieved_date'))}

                                    name="invoice_number"
                                    onChange={props.createFormHandler}
                                    id="invoice_number"
                                    defaultValue={props.currentRITNObject.invoice_number}
                                    placeholder="Invoice/Debit Note/WCN/Declaration Number"

                                />

                            </FormGroup>}
                        {((props.createForm['recieved_with_proper_doc']) && (props.createForm['recieved_with_proper_doc'] == true)) &&
                            <FormGroup>

                                <Label for="inovice date">Document  Date</Label>

                                <Flatpickr

                                    required={true}
                                    disabled={(!isPermitted('edit_part_recieved_date'))}
                                    onChange={
                                        (date, str) => {
                                            console.log(str)
                                            var e = {
                                                "target": {
                                                    type: "date",
                                                    "value": str,
                                                    name: "invoice_date"
                                                }
                                            }
                                            props.createFormHandler(e);
                                        }
                                    }
                                    defaultValue={props.currentRITNObject.invoice_date}

                                    options={{
                                        dateFormat: "d-m-Y",

                                        mode: "single"
                                    }}
                                    className="form-control" />












                            </FormGroup>}

                        <FormGroup>
                            <Label for="inovice date">Document Remarks</Label>

                            <Input
                                type="textarea"
                                rows={3}
                                readOnly={(!isPermitted('edit_part_recieved_date'))}

                                value={props.createForm['recieved_doc_description']}
                                id="recieved_doc_description"
                                name="recieved_doc_description"
                                onChange={props.createFormHandler}
                            />
                        </FormGroup>















                        <FormGroup>
                            <Label for="inovice date">Part Received  Date</Label>

                            <Flatpickr
                                disabled={

                                    (!isPermitted('edit_part_recieved_date'))
                                }
                                value={props.currentRITNObject.parts_recieved_date}


                                onChange={
                                    (date, str) => {
                                        console.log(str)
                                        var e = {
                                            "target": {
                                                type: "date",
                                                "value": str,
                                                name: "parts_recieved_date"
                                            }
                                        }
                                        props.createFormHandler(e);
                                    }
                                }

                                options={{
                                    dateFormat: "d-m-Y",

                                    mode: "single"
                                }}
                                className="form-control" />









                        </FormGroup>

                        <FormGroup>
                            <Label for="inovice date">Received with proper Packing</Label>
                            <CustomInput
                                type="switch"
                                id="recieved_with_proper_pack"
                                disabled={(!isPermitted('edit_part_recieved_date'))}

                                name="recieved_with_proper_pack"
                                onChange={props.createFormHandler}
                                checked={props.createForm['recieved_with_proper_pack']}
                            />
                        </FormGroup>



                        <FormGroup>




                            <Label for="inovice date">Part Received for Inspection Date</Label>

                            <Flatpickr
                                disabled={(!isPermitted('edit_part_recieved_inspection_date'))}


                                value={props.currentRITNObject.parts_recieved_for_inspection_date}


                                onChange={
                                    (date, str) => {
                                        console.log(str)
                                        var e = {
                                            "target": {
                                                type: "date",
                                                "value": str,
                                                name: "parts_recieved_for_inspection_date"
                                            }
                                        }
                                        props.createFormHandler(e);
                                    }
                                }

                                options={{
                                    dateFormat: "d-m-Y",

                                    mode: "single"
                                }}
                                className="form-control" />








                        </FormGroup>













                    </Col>
                    <Col lg={6}>
                        <FormGroup>
                            <Label for="invoice number">Number of Box Received</Label>
                            <Input
                                type="number"
                                min="0"
                                max="1000"
                                name="number_of_box_recieved"
                                readOnly={(!isPermitted('edit_part_recieved_date'))}

                                id="number_of_box_recieved"
                                value={props.createForm['number_of_box_recieved']}
                                placeholder="Number of Box Recived"
                                onChange={props.createFormHandler}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="invoice number">Condition packing/Box Received</Label>
                            <Input
                                type="textarea"
                                name="condition_of_packing_box"
                                readOnly={(!isPermitted('edit_part_recieved_date'))}

                                id="condition_of_packing_box"
                                rows="5"
                                onChange={props.createFormHandler}
                                value={props.createForm['condition_of_packing_box']}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for="Return Through">Return Through</Label>
                            <Select
                                name="return_through"
                                isDisabled={(!isPermitted('edit_part_recieved_date'))}

                                className="react-select"
                                classNamePrefix="react-select"
                                onChange={props.handleReturnThroughSelectChange}
                                defaultValue={defaultReturnThrough}
                                options={returnThroughOptions}></Select>
                        </FormGroup>

                        {props.CourierEnabled && (
                            <FormGroup>
                                <Label for="Courier Agent">Courier Agent</Label>
                                <Input
                                    type="text"
                                    name="corier_agent"
                                    readOnly={(!isPermitted('edit_part_recieved_date'))}

                                    id="corier_agent"
                                    onChange={props.createFormHandler}
                                    placeholder="Courier Agent"
                                    defaultValue={props.currentRITNObject.corier_agent}
                                />
                            </FormGroup>
                        )}

                        {props.CourierEnabled && (
                            <FormGroup>
                                <Label for="invoice number">Couriar Docket Number</Label>
                                <Input
                                    type="text"
                                    readOnly={(!isPermitted('edit_part_recieved_date'))}

                                    name="corier_docket_number"
                                    id="corier_docket_number"
                                    placeholder="Couriar Docket Number"
                                    onChange={props.createFormHandler}
                                    defaultValue={props.currentRITNObject.corier_docket_number}
                                />
                            </FormGroup>
                        )}
                        {


                            (props.conclusionStatus == true) &&

                            <FormGroup>
                                <Label for="Conclusion">Conclusion</Label>

                                <CreatableSelect
                                    isDisabled={(!(isPermitted('change_conclusion')))}
                                    isClearable
                                    name="conclusion"
                                    onChange={props.handleConclusionChange}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    options={props.conclusionData}
                                    value={
                                        defaultConclusion
                                    }
                                />

                            </FormGroup>
                        }

                        {
                            (props.conclusionStatus == true) && <FormGroup>
                                <Label for="invoice number">Conclusion Remarks</Label>
                                <Input
                                    type="textarea"
                                    readOnly={(!(isPermitted('change_conclusion')))}
                                    name="conclusion_remarks"
                                    id="conclusion_remarks"
                                    rows="5"
                                    onChange={props.createFormHandler}
                                    value={props.createForm['conclusion_remarks']}
                                />
                            </FormGroup>}
                    </Col>
                </Row>

                <Row>
                    <Col lg={12}>
                        <h4 className="text-muted">Service/Sales Return List</h4>
                        <AddReturn
                        spareEdit={true}
                            handleProductPartSelectChange={props.handleProductPartSelectChange}
                            handleProductPartCountChange={props.handleProductPartCountChange}
                            removeProductPart={props.removeProductPart}
                            setProductPartInput={props.setProductPartInput}
                            productPartData={props.productPartData}
                            productPartInput={props.productPartInput}
                            handleAddClick={props.handleAddClick}
                            handleRemoveClick={props.handleRemoveClick}
                            update={true}
                        />
                    </Col>
                </Row>

                <Row>

                    <Col lg={12}>
                        <div>
                            {(isPermitted('change_return')) && <FileUpload handleFiles={props.handleFiles} />}

                            <h4>Added Images</h4>

                            {
                                ((typeof (props.addedImages) != 'undefined') && (props.addedImages.length > 0)) &&
                                props.addedImages.map((imageObj, idx) => {
                                    return (
                                        <div className="p-1 px-2" key={idx}>

                                            <Row className="align-items-center">

                                                <Col className="pl-0">
                                                    <img
                                                        alt={"image"}
                                                        src={imageObj.image}
                                                    />



                                                    <a id="id1"
                                                        onClick={() => props.deleteImage(imageObj.id)}

                                                        className="btn btn-link text-danger btn-lg p-0 ml-1">

                                                        <i className="uil uil-multiply font-size-14"></i>
                                                    </a>
                                                    <UncontrolledTooltip placement="bottom" target="id1">
                                                        Delete
                                                    </UncontrolledTooltip>
                                                </Col>

                                            </Row>
                                        </div>
                                    );
                                })}
                        </div>

                    </Col></Row>
                <Row>

                    {(isPermitted('change_return')) && <Col lg={6}>
                        <FormGroup>
                            <Button color="primary"
                            
                            type="submit" onClick={() => props.onSubmit()}>
                                Submit
                            </Button>
                        </FormGroup></Col>
                    }</Row>

            </CardBody>
        </Card>
    );
};

class EditRITNForm extends Component {

    constructor(props) {

        super(props);


        var currentRITNObject = {};
        if (typeof props.location.state != 'undefined' && typeof props.location.state.ritnObject != 'undefined') {
            currentRITNObject = { ...props.location.state.ritnObject };

            this.removingProductPartInput = []

            this.state = {
                createForm: {},
                productPartInput: [],
                staffData: [],
                productPartData: [],
                newCustomerFlag: false,
                customerData: [],
                currentRITNObject: currentRITNObject,
                conclusionData: [],
                removingImages: [],


            };

            //Load basic parameters
            this.reloadCustomerData();
            this.reloadStaffData();
            this.reloadConclusionData();
            this.handleConclusionChange = this.handleConclusionChange.bind(this);
            this.reloadProductsAndParts();
            this.updateRITNProfile = this.updateRITNProfile.bind(this);
            this.handleFiles = this.handleFiles.bind(this);
            this.reloadRITNObject = this.reloadRITNObject.bind(this);
            this.createFormHandler = this.createFormHandler.bind(this);
            this.reloadCustomerData = this.reloadCustomerData.bind(this);
            this.handleCustomerSelectChange = this.handleCustomerSelectChange.bind(this);
            this.handleReturnThroughSelectChange = this.handleReturnThroughSelectChange.bind(this);
            this.handleChange = this.handleChange.bind(this);
            this.deleteImage = this.deleteImage.bind(this);
            this.ritnImages = [];
            this.reloadRITNObject();


        }
    }
 /*
   adding footers to doc
    */
    addFooters = (doc, y) => {
        const pageCount = doc.internal.getNumberOfPages();
        for (var i = 1; i <= pageCount; i++) {
            doc.setPage(i);
            var pageHeight = doc.internal.pageSize.height;
            var width = doc.internal.pageSize.width / 2;
            doc.setFont("Calibri");
            doc.setFontSize(10);
            doc.text(10, pageHeight - 20,  "Page-"+i+" of "+pageCount+" in "+this.state.currentRITNObject.ritn_number+"_profile_report");
            var user = getLoggedInUser();
            var userName = (user.username)?user.username:user.role;
            console.log("user", user)
            var time = Moment().format("dddd, MMMM Do YYYY, h:mm:ss a")
            doc.text(width+100, pageHeight - 20, "Generated by " + userName + "  at " + time);
        }


        return doc;
    }
    exportPDF = () => {

        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "landscape"; // portrait or landscape

        const marginLeft = 40;
        var doc = new jsPDF(orientation, unit, size);




        const title = "RITN details of " + this.state.currentRITNObject.ritn_number;
        var customerProfile = {};

        if (typeof (this.state.createForm['customer'])) {

            var defaultCustomerValue = this.state.customerData.
                filter((cobj => cobj.value == Number(this.state.createForm['customer'])))
            if (defaultCustomerValue && (defaultCustomerValue.length > 0)) {
                defaultCustomerValue = defaultCustomerValue[0];
                customerProfile["Name"] = defaultCustomerValue.label;
                customerProfile["Consigner Type"] = defaultCustomerValue.customer_type;
                customerProfile["Phone"] = defaultCustomerValue['phone']
                customerProfile['Location'] = defaultCustomerValue['state']
            }
        }


        customerProfile['Recieved With Proper Doc'] = ((!this.state.createForm['recieved_with_proper_doc'])

            || (this.state.createForm['recieved_with_proper_doc'] == false)) ? "No" : "Yes"
        if (this.state.currentRITNObject.invoice_number) {
            customerProfile['Document Number'] = this.state.currentRITNObject.invoice_number;
        }
        if (this.state.currentRITNObject.invoice_date) {

            customerProfile['Document Date'] = this.state.currentRITNObject.invoice_date
        }
        if (this.state.currentRITNObject.parts_recieved_for_inspection_date) {

            customerProfile["Parts Recieved For Inspection"] = this.state.currentRITNObject.parts_recieved_for_inspection_date
        }
        if (this.state.currentRITNObject.parts_recieved_date) {
            customerProfile['Parts Received Date'] = this.state.currentRITNObject.parts_recieved_date;
        }
        customerProfile['Recieved with Proper Pack'] =

            ((!this.state.createForm['recieved_with_proper_pack'])

                || (this.state.createForm['recieved_with_proper_pack'] == false)) ? "No" : "Yes";

        let defaultReturnThrough = { value: 'Courier', label: 'Courier' };

        if (this.state.createForm['return_through']) {
            var ret = returnThroughOptions.filter((cobj => cobj.value == this.state.createForm['return_through']));
            if ((ret) && (ret.length > 0)) {
                customerProfile['return_through'] = ret[0].label;
            }

        }
        if (this.state.currentRITNObject.number_of_box_recieved) {
            customerProfile['Number of Boxes Received	'] = this.state.currentRITNObject.number_of_box_recieved + "";
        }
        if (this.state.currentRITNObject.condition_of_packing_box) {

            customerProfile['Condition of Packing'] = this.state.currentRITNObject.condition_of_packing_box;
        }
        if (this.state.currentRITNObject.corier_agent) {

            customerProfile['Courier Agent'] = this.state.currentRITNObject.corier_agent;
        }
        if (this.state.currentRITNObject.corier_docket_number) {

            customerProfile['Courier Docket Number'] = this.state.currentRITNObject.corier_docket_number;
        }
        if((this.state.currentRITNObject.images)&&(this.state.currentRITNObject.images.length>0))
        {
            customerProfile['Images Attached'] = "Yes, "+this.state.currentRITNObject.images.length+" images Attached";

        }
        else
        {
            customerProfile['Images Attached'] = "No";

        }


        if (typeof (this.state.createForm['conclusion'])) {
            console.log("Conc", this.state.createForm['conclusion']);

            var defaultConclusion = this.state.conclusionData.filter((cobj => cobj.value == this.state.createForm['conclusion']));
            console.log("Conclusion Value", defaultConclusion);

            customerProfile["Conclusion"] = (defaultConclusion && defaultConclusion.length > 0) ? defaultConclusion[0].label : "Pending"
            if (this.state.createForm['conclusion_remarks']) {
                customerProfile["Conclusion Remarks"] = this.state.createForm['conclusion_remarks'];
            }

        }

        let data = [];
        const headers = [[
            "Product/Part\nName", "Quantity\nMentioned", "Quantity\nRecived",
            "Good\nItems",'Item\nTo\nBe\nChecked', "Defect\nItems", "Remarks", "ComplaintNo", "Serial Number"]];
        if ((this.state.productPartInput) && (this.state.productPartInput.length > 0)) {
            data = this.state.productPartInput.map((x, i) => {
                let defaultProdValue, defaultCount = 0, defaultMentionedCount = 0, defaultRecivedCount = 0, defaultGoodCount = 0, defaultDefectCount = 0,
                    defaultComplaintNumber = "", defaultComment = "",
                    defaultSerialNumbers = "", defaultUnchecked = 0;

                if (typeof (x.value) != 'undefined') {
                    defaultProdValue = this.state.productPartData.filter(cobj => cobj.value === x.value) // set selected value
                }

                if ((x) && (defaultProdValue) && (defaultProdValue.length > 0)) {
                    defaultMentionedCount = (x.mentioned_numbers) ? x.mentioned_numbers : 0; // set selected value
                    defaultRecivedCount = (x.recieved_numbers) ? x.recieved_numbers : 0;
                    defaultGoodCount = (x.good_item_numbers) ? x.good_item_numbers : 0;
                    defaultDefectCount = (x.defect_item_numbers) ? x.defect_item_numbers : 0;
                    defaultUnchecked = (x.items_to_be_checking_numbers) ? x.items_to_be_checking_numbers : 0;
                    defaultComment = x.comment;
                    defaultComplaintNumber = x.complaint_number;
                    defaultSerialNumbers = x.serial_numbers;

                    let name = (defaultProdValue[0].label) ? defaultProdValue[0].label : "";
                    return [name,
                        defaultMentionedCount,
                        defaultRecivedCount,
                        defaultGoodCount,
                        defaultUnchecked,

                        defaultDefectCount,

                        defaultComment,
                        defaultComplaintNumber,
                        defaultSerialNumbers]

                }
            })

        }

        data = data.filter(function (element) {
            return element !== undefined;
        });








        let y = 0;

        doc.setFont("Calibri");
        doc.setFontSize(14);
        doc.setTextColor(0, 0, 0)
        doc.text(20, 50, title);

        doc.line(0, 60, 600, 60); // horizontal line

        doc.setTextColor(0, 0, 0)
        doc.setFontSize(11);

        let docDic = this.setOnDoc(doc, 20, 100, customerProfile);
        doc = docDic.doc;
        y = docDic.y;
        doc.addPage();
        y = 50 // Restart height position

        y = y + 50;
        doc.setFontSize(13);
        doc.text(20, y, "Returned Parts");
        doc.setFontSize(2);
        y = y + 30;

        if ((data)) {

            let content = {
                startY: y,
                head: headers,
                body: data
            }
            doc.autoTable(content);

        };
        doc = this.addFooters(doc);

        /*
        y=y+(data.length*55);
        pageHeight= doc.internal.pageSize.height;

        // Before adding new content
         // Height position of new content
        if (y >= pageHeight-200)
        {

          doc.addPage();
          y = 50 // Restart height position
        }
        let imagex=10;
        {
        if(this.state.currentRITNObject.images)
        {
            doc.text(20, y, "Added Images");
            y=y+30;

            this.state.currentRITNObject.images.map((imageObj)=>{
                if(imagex>700)
                {
                    imagex=10;
                    y=y+120;
                    pageHeight= doc.internal.pageSize.height;

                    // Before adding new content
                     // Height position of new content
                    if (y >= pageHeight-200)
                    {
                      doc.addPage();
                      y = 50 // Restart height position
                    }
    
                }
                doc.addImage(imageObj.image, 'JPEG', imagex, y, 100, 100);
            imagex=imagex+150;

            });

        }}*/
        var fileName= this.state.currentRITNObject.ritn_number+"_profile_report";
        doc.save(fileName+".pdf");
    }





    setOnDoc = (doc, x, y, keyValueDic) => {

        var intialX = x;
        Object.keys(keyValueDic).map((key, index) => {
            x = intialX;
            y = y + 20

            doc.setFontSize(12);
            doc.text(x, y, key);
            x = x + 200;
            doc.text(x, y, ":");
            x = x + 50;

            doc.setFontSize(10);
            if (keyValueDic[key]) {
                doc.text(x, y, keyValueDic[key]);
            }


        });
        return { "doc": doc, x: x, y: y };
    }





    reloadStaffData = () => {
        let inputParams = {};
        StaffService.getData(inputParams).then((response) => {
            let abstractData = response.data.data.map((obj) => ({ label: obj.name, value: obj.user }));
            this.setState({ staffData: abstractData });
        });
    };
    setProductPartInput = (prodPartInput) => {
        this.setState({ productPartInput: prodPartInput });
    };

    componentDidMount() {
        this.reloadRITNObject();
    }

    reloadRITNObject = () => {
        let currentRITNObject = {};

        let inputParams = {};
        RITNService.getIndividualData(this.state.currentRITNObject.id, inputParams).then((response) => {



            currentRITNObject = response.data
            let defaultCustomerValue = 0, defaultState, defaultCustomerType;
            let defaultConclusion = 0;
            let defaultPhone = "0000000000";
            if (currentRITNObject != 'undefined') {
                if ((currentRITNObject.customer) && (currentRITNObject.customer.id)) {
                    defaultCustomerValue = currentRITNObject.customer.id;
                    defaultPhone = currentRITNObject.customer.phone;
                    defaultState = currentRITNObject.customer.state;
                    defaultCustomerType = currentRITNObject.customer.customer_type;
                }

                if ((currentRITNObject.conclusion) && (currentRITNObject.conclusion.id)) {
                    defaultConclusion = currentRITNObject.conclusion.id;

                }

            }
            this.removingProductPartInput = []
            let totalCount = currentRITNObject.added_check_lists.length;
            let countOpen = currentRITNObject.added_check_lists.reduce((n, x) => n + (x.status === false), 0);
            let countClosed = totalCount - countOpen;
            this.setState({
                createForm: {
                    customer: defaultCustomerValue,
                    customer_type: defaultCustomerType,
                    state: defaultState,
                    phone: defaultPhone,
                    recieved_doc_description: currentRITNObject.recieved_doc_description,
                    recieved_with_proper_doc: currentRITNObject.recieved_with_proper_doc,
                    recieved_with_proper_pack: currentRITNObject.recieved_with_proper_pack,
                    conclusion: defaultConclusion,
                    recieved_with_proper_pack: currentRITNObject.recieved_with_proper_pack,
                    number_of_box_recieved: currentRITNObject.number_of_box_recieved,
                    condition_of_packing_box: (currentRITNObject.condition_of_packing_box) ? currentRITNObject.condition_of_packing_box : "",
                    conclusion_remarks: (currentRITNObject.conclusion_remarks) ? currentRITNObject.conclusion_remarks : ""
                },

                newCustomerFlag: false,
                productPartInput: this.getProductPartInput(currentRITNObject),

                currentRITNObject: currentRITNObject,
                CourierEnabled: (currentRITNObject.return_through == 'Courier'),
                addedImages: [...currentRITNObject.images],
                removingImages: [],
                conclusionStatus: (countOpen == 0)


            });

            this.forceUpdate();
        });
    }












    handleChange = (newValue) => {
        let inputForm = { ...this.state.createForm };
        if (newValue) {

            inputForm['customer'] = newValue.value;
            inputForm['customer_name'] = newValue.label;
            inputForm['phone'] = newValue.phone;
            inputForm['customer_type'] = newValue.customer_type;
            inputForm['state'] = newValue.state;


        }
        this.setState({ createForm: inputForm });
    };



    handleConclusionChange = (newValue, actionMeta) => {

        let inputForm = { ...this.state.createForm };
        console.log(newValue);
        if ((newValue) && (newValue != null)) {

            if ((newValue.__isNew__)) {
                inputForm['conclusion'] = '0';
                inputForm['conclusion_title'] = newValue.value;
                let data = [...this.state.conclusionData]
                data = data.filter((cobj => cobj.value != '0'));
                data.push({ label: newValue.value, value: '0' })

                this.setState({ conclusionData: data });



            } else {
                inputForm['conclusion'] = newValue.value;



            }
        }

        this.setState({ createForm: inputForm });
    };


   
    /*
       fetch elements from server and reload data elements
       */
    /*
       fetch elements from server and reload data elements
       */
    reloadCustomerData = () => {
        let inputParams = {};
        CustomerService.getData(inputParams).then((response) => {
            let abstractData = response.data.data.map((obj) => ({
                label: obj.name,
                value: obj.id,
                phone: obj.phone,
                customer_type: obj.customer_type,
                state: obj.state
            }));

            this.setState({ customerData: abstractData });
        });
    }

    /*
    fetch elements from server and reload data elements
    */
    /*
       fetch elements from server and reload data elements
       */
    reloadConclusionData = () => {
        let inputParams = {};
        RITNService.getConclusions().then((response) => {
            let abstractData = response.data.data.map((obj) => ({ label: obj.title, value: obj.id }));

            this.setState({ conclusionData: abstractData });
        });
    };

    /*
    fetch elements from server and reload data elements
    */
    reloadProductsAndParts = () => {
        let partData = [],
            productData = [];
        ProductPartService.getData({}).then((part_response) => {
            ProductService.getData({}).then((product_response) => {
                let part_data = part_response.data.data.map((obj) => ({
                    label: obj['part_name'],
                    value: 'part-' + obj.id,
                }));
                let prod_data = product_response.data.data.map((obj) => ({
                    label: obj['product_name'],
                    value: 'prod-' + obj.id,
                }));
                let abstractData = part_data.concat(prod_data);
                this.setState({ productPartData: abstractData });
            });
        });
    };

    handleCustomerSelectChange = (selectedOption) => {
        this.state.createForm['customer'] = selectedOption.value;
        this.setState({ newCustomerFlag: (0 === selectedOption.value) })

    };

    handleReturnThroughSelectChange = (selectedOption) => {

        this.state.createForm['return_through'] = selectedOption.value;

        if ((selectedOption.value) === 'Courier') {
            this.setState({ CourierEnabled: true });

        }
        else {

            this.setState({ CourierEnabled: false });
            this.state.createForm['corier_agent'] = 'NA';
            this.state.createForm['corier_docket_number'] = 'NA';


        }

    }

    getProductPartInput = (ritnObject) => {
        if (typeof ritnObject == 'undefined') {
            return [{
                id: 0, value: '', mentioned_numbers: 0, recieved_numbers: 0,
                serial_numbers: "", items_to_be_checking_numbers: 0,
                good_item_numbers: 0, defect_item_numbers: 0,
                complaint_number: "unknown", comment: "", action: CREATE
            }];
        }
        let defaultInputList = [];
        if (typeof ritnObject != 'undefined') {
            ritnObject.product_parts.map((part_obj) => {
                defaultInputList.push({
                    id: part_obj.id,
                    value: 'part-' + part_obj.product_part.id,
                    mentioned_numbers: Number(part_obj.mentioned_numbers), // set selected value
                    recieved_numbers: Number(part_obj.recieved_numbers),
                    good_item_numbers: Number(part_obj.good_item_numbers),
                    defect_item_numbers: Number(part_obj.defect_item_numbers),
                    items_to_be_checking_numbers: Number(part_obj.items_to_be_checking_numbers),
                    complaint_number: part_obj.complaint_number,
                    comment: part_obj.comment,
                    serial_numbers: part_obj.serial_numbers,
                    action: UPDATE
                });
            });
        }

        if (typeof ritnObject.products != 'undefined') {
            ritnObject.products.map((prod_obj) => {
                defaultInputList.push({

                    id: prod_obj.id,
                    value: 'prod-' + prod_obj.product.id,
                    mentioned_numbers: Number(prod_obj.mentioned_numbers), // set selected value
                    recieved_numbers: Number(prod_obj.recieved_numbers),
                    good_item_numbers: Number(prod_obj.good_item_numbers),
                    defect_item_numbers: Number(prod_obj.defect_item_numbers),
                    items_to_be_checking_numbers: Number(prod_obj.items_to_be_checking_numbers),
                    complaint_number: prod_obj.complaint_number,
                    comment: prod_obj.comment,
                    serial_numbers: prod_obj.serial_numbers,

                    action: UPDATE

                });
            });
        }
        defaultInputList = defaultInputList.length > 0 ? defaultInputList : [{
            id: 0, value: '', mentioned_numbers: 0, recieved_numbers: 0, good_item_numbers: 0, defect_item_numbers: 0,

            serial_numbers: "",

            complaint_number: "unknown", comment: "", action: CREATE
        }];
        return defaultInputList;
    };
    deleteImage = (imageId) => {
        let currentAddedImages = this.state.addedImages.filter((imgObj) => {
            return (imgObj.id != imageId)
        });
        this.setState({ addedImages: currentAddedImages });
        let removingImages = [...this.state.removingImages];
        removingImages.push(imageId);
        this.setState({ removingImages: removingImages });


    }
    reloadDefaultProductsAndParts = () => {
        this.setState({
            productPartInput: this.getProductPartInput(this.state.currentRITNObject),
        });
    };

    /*

handles event of product partb select box

    */

    // handle when product part input changes


    removeProductPart(index) {
        let list = [...this.state.productPartInput];
        let removedObj = { ...list[index] }
        if (removedObj.action == UPDATE) {
            removedObj['action'] = DESTROY
            this.removingProductPartInput.push(removedObj)
        }
        list.splice(index, 1);
        this.setState({ productPartInput: list });
    }







    // handle when product part input changes
    handleProductPartSelectChange = (e, index) => {
        let list = [...this.state.productPartInput];
        if (e != 'undefined') {

            if (typeof (list[index]) == 'undefined') {
                list.push({
                    id: 0, mentioned_numbers: 0,
                    recieved_numbers: 0,
                    good_item_numbers: 0,
                    items_to_be_checking_numbers: 0,
                    defect_item_numbers: 0,
                    complaint_number: "unknown", comment: "",
                    serial_numbers: "",

                    action: CREATE
                });
            }
            else {
                let updateObj={...list[index]};
                if (updateObj.action == UPDATE) {
                    updateObj['action'] = DESTROY
                    this.removingProductPartInput.push(updateObj)
                }
            

                list[index]['value'] = e.value;
                list[index]['action'] =CREATE;
            }
        }
        console.log(list)

        this.setState({ productPartInput: list });

    };





    checkKeyStatus = (tempElement, keyName) => {

        if ((keyName == 'recieved_numbers') ||
            (keyName == 'good_item_numbers') ||
            (keyName == 'defect_item_numbers')) {
            tempElement['items_to_be_checking_numbers']
                = Number(tempElement['recieved_numbers']) - (
                    Number(tempElement['good_item_numbers'])
                    + Number(tempElement['defect_item_numbers'])


                )
        }
        else if (keyName == 'items_to_be_checking_numbers') {
            tempElement['recieved_numbers']
                = Number(tempElement['items_to_be_checking_numbers'])
                +
                Number(tempElement['good_item_numbers']) +
                Number(tempElement['defect_item_numbers'])



        }

        return tempElement;
    }
    /*

handles event of product partb select box

    */
    /*

handles event of product partb select box

    */
    handleProductPartCountChange = (event, index) => {
        console.log('called');
        let countValue = event.target.value;
        let keyName = event.target.name;
        console.log(keyName);
        console.log(countValue)
        let list = [...this.state.productPartInput];
        if (typeof list[index] == 'undefined') {
            let tempElement = {
                id: 0, value: '',
                mentioned_numbers: 0,
                items_to_be_checking_numbers: 0,
                recieved_numbers: 0,
                good_item_numbers: 0,
                defect_item_numbers: 0,
                complaint_number: "unknown",

                comment: "",
                serial_numbers: "",

                action: CREATE
            };
            tempElement[keyName] = countValue;
            tempElement = this.checkKeyStatus(tempElement, keyName);
            if ((tempElement['recieved_numbers'] >= 0) &&
                (tempElement['items_to_be_checking_numbers'] >= 0)) {
                list.push(tempElement);


            }






            list.push(tempElement);
        } else {
            var tempElement = list[index];
            tempElement[keyName] = countValue;

            tempElement = this.checkKeyStatus(tempElement, keyName);
            if ((tempElement['recieved_numbers'] >= 0) &&
                (tempElement['items_to_be_checking_numbers'] >= 0)) {
                list[index] = tempElement;


            }








        }
        this.setState({ productPartInput: list });
    };

    createFormHandler = (event) => {
        let key = '';
        let value = '';

        let inputForm = this.state.createForm;

        if (event.target.type == 'text' || event.target.type == 'textarea' || event.target.type == 'number') {
            key = event.target.name;
            value = event.target.value;
        } else if (event.target.type == 'date') {
            key = event.target.name;
            value = event.target.value;

            console.log(value);
        } else if (event.target.type == 'checkbox') {
            key = event.target.name;
            value = event.target.checked;
            console.log(key, value);
        } else if (event.target.type == 'file') {
            key = event.target.name;
            value = event.target.files[0];
        }
        if (key == 'excelFile') {
            this.state.uploadExcelForm[key] = value;
        } else {
            if (key) {
                inputForm[key] = value;
                this.setState({ createForm: inputForm });
            }
        }
    };

    handleFiles = (fileList) => {
        if (fileList) {
            this.ritnImages = fileList;
        }
    };

    updateRITNProfile = () => {
        let inputParams = this.state.createForm;
        let formData = new FormData(); //formdata object
        Object.keys(inputParams).forEach(function (key) {
            formData.append(key, inputParams[key]); //append the values with key, value pair
        });

        let prodPartInputs = [...this.state.productPartInput, ...this.removingProductPartInput]

        if ((prodPartInputs.length > 0)&&(isPermitted("change_sales_return")))
        {
            let returnedProducts = [];
            let retunedProductParts = [];
            console.log('prodPartInputs', prodPartInputs);
            prodPartInputs.map((obj) => {
                if (obj.value) {
                    let objInput = obj.value.split('-')[1];

                    if (obj.value.startsWith('prod')) {
                        obj.value = objInput
                        returnedProducts.push(obj);

                    } else {
                        obj.value = objInput
                        retunedProductParts.push(obj);

                    }
                }
            });

            formData.append('returned_products', JSON.stringify(returnedProducts));
            formData.append('returned_product_parts', JSON.stringify(retunedProductParts));
        }
        for (let i = 0; i < this.ritnImages.length; i++) {
            formData.append('ritn_images', this.ritnImages[i]);
        }
        if ((typeof (this.state.removingImages) != 'undefined') && (this.state.removingImages.length > 0)) {
            formData.append('removing_images', JSON.stringify(this.state.removingImages));

        }
        RITNService.updateDataFromForm(formData, this.state.currentRITNObject.id)
            .then((response) => {
                if (response.data['status'] === 'Sucess') {
                    alertCreation('RITN Profile');
                    window.location = currentURL + "apps/return/list";



                } else {
                    alertError(response.data['message']);
                }
            })
            .catch((err) => alert(err));
    }



    // handle click event of the Remove button
    handleRemoveClick = (index) => {
        const list = [...this.state.productPartInput];
        let removedObj = { ...list[index] }
        if (removedObj.action == UPDATE) {
            removedObj['action'] = DESTROY
            this.removingProductPartInput.push(removedObj)
        }
        list.splice(index, 1);
        this.setProductPartInput(list);
    };

    // handle click event of the Add button
    handleAddClick = () => {
        let list = [...this.state.productPartInput];
        list.push({ id: 0, value: '', mentioned_numbers: 0, recieved_numbers: 0, good_item_numbers: 0, defect_item_numbers: 0, action: CREATE });
        this.setProductPartInput(list);
        console.log(list);
    };
    deleteConfirm = (id) => {
        confirmDeleteAction('RITN').then((userResponse) => {
            if (userResponse.isConfirmed == true) {
                this.deleteRITN(id);
            }
        });
    };

    deleteRITN = (id) => {
        let inputParams = {};
        inputParams['id'] = id;
        RITNService.deleteData(inputParams)
            .then((response) => {
                console.log(response.data.status);

                if (response.data['status'] === 'sucess') {
                    alertDeletion('RITN');
                    window.location = currentURL + "apps/return/list";
                } else {
                    alertError(response.data['message']);
                }
            })
            .catch((err) => alert(err));
    };


    render() {
        return (
            <React.Fragment>
                <Row className="page-title">
                    <Col md={12}>
                        <PageTitle
                            breadCrumbItems={[
                                { label: 'Forms', path: '/forms/basic' },
                                { label: 'New Return', path: '/forms/basic', active: true },
                            ]}
                            title={'New Return'}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <RITNInputElements
                            newCustomerFlag={this.state.newCustomerFlag}
                            handleFiles={this.handleFiles}
                            createFormHandler={this.createFormHandler}
                            createForm={this.state.createForm}
                            onSubmit={this.updateRITNProfile}
                            CourierEnabled={this.state.CourierEnabled}
                            customerData={this.state.customerData}
                            conclusionData={this.state.conclusionData}
                            handleChange={this.handleChange}
                            handleConclusionChange={this.handleConclusionChange}
                            handleProductPartSelectChange={this.handleProductPartSelectChange}
                            handleProductPartCountChange={this.handleProductPartCountChange}
                            handleReturnThroughSelectChange={this.handleReturnThroughSelectChange}
                            removeProductPart={this.removeProductPart}
                            setProductPartInput={this.setProductPartInput}
                            productPartData={this.state.productPartData}
                            productPartInput={this.state.productPartInput}
                            handleAddClick={this.handleAddClick}
                            handleRemoveClick={this.handleRemoveClick}
                            currentRITNObject={this.state.currentRITNObject}
                            addedImages={this.state.addedImages}
                            deleteImage={this.deleteImage}
                            conclusionStatus={this.state.conclusionStatus}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xl={6}>
                        <Button className="float-right" size={'sm'}
                            onClick={() => this.exportPDF(this.state.currentRITNObject)}
                            color="primary">
                            <i className='uil uil-export ml-1'></i> Export As PDF
                        </Button>
                    </Col>

                    <Col xl={6}>

                        {/* <Button className="float-right" size={'sm'}
            onClick={() =>{
                
                if(this.state.conclusionStatus==true)
                {this.deleteConfirm(this.state.currentRITNObject.id);
                }
            else{
alertError("Please close all pending issues to Delete this Profile");
            }
            }}
            color="danger">
                    <i className='uil uil-delete ml-1'>X</i> Delete
        </Button>*/}
                    </Col>

                </Row>
                {this.state.currentRITNObject.added_check_lists && <Row>
                    <Col xl={12}>

                        <hr></hr>
                        <h6 className="mt-0 header-title">CheckLists</h6>

                        <Card>
                            <CardBody>
                                {this.state.currentRITNObject.added_check_lists.map((checkListObj) => {

                                    return <CheckListCard
                                        staffData={this.state.staffData}
                                        checkListObj={checkListObj}
                                        reloadRITNObject={this.reloadRITNObject}


                                    />

                                })}

                            </CardBody>
                        </Card>
                    </Col>

                </Row>}

            </React.Fragment>
        );
    }
}
export default EditRITNForm;
