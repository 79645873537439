import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
    Row,
    Col,
    Card,Label,Input,
    CardBody,
    Button,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,FormGroup,
    UncontrolledTooltip,
    UncontrolledButtonDropdown,
    Collapse,
    CustomInput,
} from 'reactstrap';
import classNames from 'classnames';
import { Loader } from 'react-feather';
import Moment from 'moment';
import ProductPartService from '../../../services/ProductPartService';
import ProductService from '../../../services/ProductPartService';

import Badge from 'reactstrap/lib/Badge';
import PageTitle from '../../../components/PageTitle';
import { AvForm, AvField, AvGroup } from 'availity-reactstrap-validation';
import { todayTasks, yestrday, otherTasks } from './Data';
import Flatpickr from 'react-flatpickr';
import { issueStatus, customerTypes } from '../../../helpers/globalConstants';
import Select, { components } from 'react-select';

import CheckListService from '../../../services/RITNService';
import RITNService from '../../../services/RITNService';
import { toFullDate, isNow, isAgo } from '../../../services/DataFormatHandler';
import IINService from '../../../services/IINService';
import moment from 'moment';
import { isPermitted } from '../../../helpers/permission-manager';
const convert=(t)=> 
{       let timeObj=""
        if(t==false)
        {
            timeObj=Moment().utc().format('YYYY-MM-DD HH:mm:ss');

        }
        else
        {
            timeObj=Moment(t,'YYYY-MM-DD').utc().format('YYYY-MM-DD HH:mm:ss');
        }
        return timeObj;

}
const ritnVerificationStatus = (verificationStatus) => {


    return (
        <span
            className={classNames(
                'badge',
                {
                    'badge-soft-danger': verificationStatus === 'Not Verified',
                    'badge-soft-success': verificationStatus === 'Verified',
                },
                'p-1'
            )}>
            {verificationStatus}
        </span>
    );


}

const IINData = (props) => {
    const clickHandler = props.onClickHandler || {};
    const iinObj = props.iinObj;
    console.log(iinObj);
    let publisedDate =iinObj.published_date;
    let selectedIIN = props.selectedIIN;

    return (
        <React.Fragment>
            <Row
                className={classNames('justify-content-sm-between', 'border-bottom', 'mt-2', 'pt-2', 'cursor-pointer')}
                onClick={() => clickHandler(iinObj)}>
                <Col lg={3} className="mb-lg-0">
                    <h6 id="sidebarLabels"> {iinObj.item_issue_number} </h6>


                    <span
                        className={classNames(
                            'badge',
                            {
                                'badge-soft-danger': iinObj.issue_profile_status == 'Open',
                                'badge-soft-success': iinObj.issue_profile_status == 'Closed',
                            },
                            'p-1'
                        )}>
                           
                    </span>
                </Col>
                <Col lg={3} className="mb-lg-0">

</Col>
                <Col lg={6}>
                    <div>

                        <div className="mt-3 mt-sm-0">
                            <ul className="list-inline font-13 text-sm-right">
                                <li className="list-inline-item pr-1">
                                    <i className="uil uil-schedule font-16 mr-1"></i>
                                    {publisedDate
                                        
                                    }
                                    {
                                          <Badge bg="info">
                                          {moment(publisedDate,"DD-MM-YYYY h:mm:ss a").fromNow()}
                                              </Badge>
                                    }

                                    <i className="uil uil-schedule font-16 mr-1"></i>

                                </li>
                                <li className="list-inline-item pr-1">
                                    <i className="uil uil-align-alt font-16 mr-1"></i>
                                    added By {iinObj.created_staff_name}

                                </li>
                                <li className="list-inline-item"></li>
                            </ul>
                        </div>
                    </div>
                </Col>
                <Col lg={3}>

                    <Link

                        to={
                            {
                                pathname: "/issue/edit",
                                state: { iinObject: iinObj, update: true }
                            }
                        }
                    >
                        <button type="button" className="btn btn-soft-primary btn-sm">
                            <i className="uil uil-edit mr-1"></i>
                            
                            {(isPermitted('change_issue'))?"View and Edit":"View"}
                            
                            </button>
                    </Link>

                </Col>
            </Row>
        </React.Fragment>
    );
};

class IINList extends Component {
    constructor(props) {
        super(props);

        this.togglePanel = this.togglePanel.bind(this);
        this.selectTask = this.selectTask.bind(this);
        this.state = {
            todayTaskCollapse: true,
            upcomingTaskCollapse: true,
            otherTaskCollapse: true,
            allIINObjects: [],
            todayIINObjects: [],
            yesterdayIINObjects: [],
            selectedTask: todayTasks[0],
            selectedIIN: "",
            searchForm:{}

        };
        this.selectedIINProfile = this.selectedIINProfile.bind(this);
        this.handleSelectIINClick = this.handleSelectIINClick.bind(this);
        this.reloadData();
    }



    clearState=(event)=>{

        this.setState({searchForm:{}},()=>{this.reloadData();})
    }

    createFormHandler = (event) => {
        let key = '';
        let value = '';

        let inputForm ={...this.state.searchForm};

        console.log(event.target.type);
        if ((event.target.type == 'text')||(event.target.type == 'textarea') ){
            key = event.target.name;
            value = event.target.value;
        } else if (event.target.type == 'date') {
            key = event.target.name;
            value = event.target.value;

            console.log(value);
        } else if (event.target.type == 'checkbox') {
            key = event.target.name;
            value = event.target.checked;
            console.log(key, value);
        } else if (event.target.type == 'file') {
            key = event.target.name;
            value = event.target.files[0];
        }
        if (key == 'excelFile') {
            this.state.uploadExcelForm[key] = value;
        } else {
            if (key) {

                inputForm[key] = value;
                if(value.length==0)
                {
                    delete inputForm[key]
                }
                this.setState({ searchForm: inputForm });
            }
        }
    };


   // handle whenissue status input changes
   handleCustomerTypeSelectChange = (e) => {
    let inputParams = {...this.state.searchForm};
    if (e != 'undefined') {
        inputParams['customer_type'] = e.value
    }
    this.setState({ searchForm: inputParams });

};
    searchKeywordHandler=(event)=>{

        let keyWord= event.target.value;
        if((keyWord=="")||(keyWord.length==0)||(!keyWord))
        {
          this.reloadData();
        }
        else{
          let itemsDisplayedCopy=[...this.state.allIINObjects];
          let searchedData= itemsDisplayedCopy.filter(item=>(((""+item.id).includes(keyWord))));
          this.setState({allIINObjects:searchedData})
        }
      }
      handleSelect=(ranges)=>{
     ;
        if(ranges){
          var opening_date=convert(ranges.selection.startDate);
          var closing_date=convert(ranges.selection.endDate);
          let inputParams={...this.state.searchForm};
          inputParams['opening_date']=opening_date;
          inputParams['closing_date']=closing_date;
    
          this.setState({
            selectionRange : {
              startDate: ranges.selection.startDate,
              endDate: ranges.selection.endDate,
              key: "selection"
            },
            searchForm:inputParams
    
    
          })
        
    
        }

    }



    /**
     * Toggle panel
     */
    togglePanel(panel) {
        var state = { ...this.state };
        state[panel] = !state[panel];
        this.setState(state);
    }
    componentDidMount=()=>{
        this.reloadProductsAndParts();
    }
    /*
    fetch elements from server and reload data elements
    */
    reloadData = () => {
        let inputParams =(!this.state.searchForm)?{}:{...this.state.searchForm};
        console.log(inputParams);
        IINService.getData(inputParams).then((response) => {
            let currentData = response.data.data;
            console.log("Current Data",currentData);

            let todayData = currentData.filter((iinObj) => { return isNow(iinObj.published_date) })
            let yesterdayData = currentData.filter((iinObj) => { return isAgo(iinObj.published_date, 1) })
            this.setState({ yesterdayIINObjects: yesterdayData });

            this.setState({ allIINObjects: currentData });
            this.setState({ todayIINObjects: todayData });
            this.selectedIINProfile(todayData[0])


        });
    };

    reloadProductsAndParts = () => {
        let partData = [],
            productData = [];
            ProductPartService.getData({}).then((part_response) => {
            ProductService.getData({}).then((product_response) => {
                let part_data = part_response.data.data.map((obj) => ({
                    label: obj['part_name'],
                    value: 'part-' + obj.id,
                }));
                let prod_data = product_response.data.data.map((obj) => ({
                    label: obj['product_name'],
                    value: 'prod-' + obj.id,
                }));
                let abstractData = part_data.concat(prod_data);
                abstractData=[{label:"All Parts",value:"All_0"},...abstractData]
                this.setState({ productPartData: abstractData });
            });
        });
    };

    // handle when product part input changes
    handleProductPartSelectChange = (e, index) => {
        let inputForm ={...this.state.searchForm};
        if (e != 'undefined') {
            var value=e.value;

            if (value) {
                let objInput = value.split('-')[1];
                if (value.startsWith('prod')) {
                    inputForm['product']=objInput;
                    } 
                    
                    else if (value.startsWith('part'))
                    {
                        inputForm['product_part']=objInput;


                }
                else{
                    if( inputForm['product'])
                    {
                        delete inputForm['product'];
                    }
                    if( inputForm['product_part'])
                    {
                        delete inputForm['product_part'];
                    }

                }
            }

        }

        this.setState({ searchForm: inputForm });

    };

    handleSelectIINClick = (iinObj) => {
        this.selectedIINProfile(iinObj)

    }
    /**
     * Selects the task
     * @param {*} task
     */
    selectedIINProfile(iinObj) {
        this.setState({ selectedIIN: iinObj });
    }


    /**
     * Selects the task
     * @param {*} task
     */
    selectTask(task) {
        this.setState({ selectedTask: task });
    }

    render() {
        return (
            <React.Fragment>
                <Row className="page-title">
                    <Col md={12}>
                        <PageTitle
                            breadCrumbItems={[
                                { label: 'Apps', path: '/apps/issue/list' },
                                { label: 'Tasks', path: '/apps/issue/list', active: true },
                            ]}
                            title={'Issued Profile Lists'}
                        />
                    </Col>
                </Row>

                {/* filter panel */}
                <Row>
                    <Col xl={8}>
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col sm={3}>
                                            {isPermitted('change_issue')&&
 <Link to="/apps/issue/new">
                                                    <Button renderAs="button" color="primary">
                                                        <i className="uil uil-plus mr-1"></i>Add IIN
  </Button>
                                                </Link>}

                                            </Col>

                                            <Col sm={9}>
                                                <div className="float-sm-right mt-3 mt-sm-0">
                                                    <div className="task-search d-inline-block mb-3 mb-sm-0 mr-sm-3">
                                                        <form>
                                                            <div className="input-group">
                                                                <input
                                                                    type="text"
                                                                    onChange={this.searchKeywordHandler}
                                                                    className="form-control search-input"
                                                                    placeholder="Search by IIN/Invoice Number/Courier Number"
                                                                />
                                                                <span className="uil uil-search icon-search"></span>
                                                                <div className="input-group-append">
                                                                    <button
                                                                        className="btn btn-soft-primary"
                                                                        type="button">
                                                                        <i className="uil uil-file-search-alt"></i>
                                                                    </button>
                                                                    
                                                           
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>

                                                    <UncontrolledButtonDropdown className="d-inline-block">
                                                        <DropdownToggle
                                                            tag="button"
                                                            className="btn btn-secondary dropdown-toggle">
                                                            <i className="uil uil-sort-amount-down"></i>
                                                        </DropdownToggle>
                                                        <DropdownMenu right>
                                                            <DropdownItem>
                                                       </DropdownItem>

                                                        </DropdownMenu>
                                                    </UncontrolledButtonDropdown>
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row className="mt-4">
                                            <Col>
                                                <Link
                                                    to="#"
                                                    id="toggler"
                                                    className="p-0 text-dark"
                                                    onClick={() => {
                                                        this.togglePanel('todayTaskCollapse');
                                                    }}>
                                                    <h5 className="mb-0">
                                                        {this.state.todayTaskCollapse && (
                                                            <i className="uil uil-angle-down font-size-18 align-middle"></i>
                                                        )}
                                                        {!this.state.todayTaskCollapse && (
                                                            <i className="uil uil-angle-right font-size-18 align-middle"></i>
                                                        )}
                                                        Today{' '}
                                                        <span className="text-muted font-size-14">
                                                            ({this.state.todayIINObjects.length})
                                                        </span>
                                                    </h5>
                                                </Link>
                                                <Collapse isOpen={this.state.todayTaskCollapse}>
                                                    <Card className="mb-0 shadow-none">
                                                        <CardBody>
                                                            {this.state.todayIINObjects.map((iinObj, index) => {
                                                                return <IINData iinObj={iinObj}
                                                                    selectedIIN={this.state.selectedIIN}
                                                                    onClickHandler={this.handleSelectIINClick} key={index} />;
                                                            })}
                                                        </CardBody>
                                                    </Card>
                                                </Collapse>

                                                <div className="mt-4">
                                                    <Link
                                                        to="#"
                                                        id="yestrday"
                                                        className="p-0 text-dark"
                                                        onClick={() => {
                                                            this.togglePanel('upcomingTaskCollapse');
                                                        }}>
                                                        <h5 className="mb-0">
                                                            {this.state.upcomingTaskCollapse && (
                                                                <i className="uil uil-angle-down font-size-18 align-middle"></i>
                                                            )}
                                                            {!this.state.upcomingTaskCollapse && (
                                                                <i className="uil uil-angle-right font-size-18 align-middle"></i>
                                                            )}
                                                            Yestrday{' '}
                                                            <span className="text-muted font-size-14">
                                                                ({this.state.yesterdayIINObjects.length})
                                                            </span>
                                                        </h5>
                                                    </Link>
                                                    <Collapse isOpen={this.state.upcomingTaskCollapse}>
                                                        <Card className="mb-0 shadow-none">
                                                            <CardBody>
                                                                {this.state.yesterdayIINObjects.map((iinObj, index) => {
                                                                    return <IINData iinObj={iinObj}
                                                                        selectedIIN={this.state.selectedIIN}

                                                                        onClickHandler={this.handleSelectIINClick} key={index} />;
                                                                })}


                                                            </CardBody>
                                                        </Card>
                                                    </Collapse>
                                                </div>

                                                <div className="mt-4">
                                                    <Link
                                                        to="#"
                                                        id="otherTasks"
                                                        className="p-0 text-dark"
                                                        onClick={() => {
                                                            this.togglePanel('otherTaskCollapse');
                                                        }}>
                                                        <h5 className="mb-0">
                                                            {this.state.otherTaskCollapse && (
                                                                <i className="uil uil-angle-down font-size-18 align-middle"></i>
                                                            )}
                                                            {!this.state.otherTaskCollapse && (
                                                                <i className="uil uil-angle-right font-size-18 align-middle"></i>
                                                            )}
                                                            All{' '}
                                                            <span className="text-muted font-size-14">
                                                                ({this.state.allIINObjects.length})
                                                            </span>
                                                        </h5>
                                                    </Link>
                                                    <Collapse isOpen={this.state.otherTaskCollapse}>
                                                        <Card className="mb-0 shadow-none">
                                                            <CardBody>
                                                                <Row
                                                                    className={classNames(
                                                                        'justify-content-sm-between',
                                                                        'border-bottom',
                                                                        'mt-2',
                                                                        'pt-2',
                                                                        'cursor-pointer'
                                                                    )}>
                                                                    <Col lg={3} className="mb-lg-0">
                                                                        <h6>IIN Number</h6>
                                                                    </Col>
                                                                    <Col lg={9}>
                                                                        <div className="d-sm-flex justify-content-between">
                                                                            <div>
                                                                                <h6>Parts Lists</h6>
                                                                            </div>

                                                                            <div className="mt-3 mt-sm-0">
                                                                                <h6>Published Date</h6>
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                {this.state.allIINObjects.map((iinObj, index) => {
                                                                    return <IINData iinObj={iinObj}
                                                                        selectedIIN={this.state.selectedIIN}

                                                                        onClickHandler={this.handleSelectIINClick} key={index} />;
                                                                })}
                                                            </CardBody>
                                                        </Card>
                                                    </Collapse>
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row className="mb-3 mt-5">
                                            <Col>
                                                <div className="text-center">
                                                    <a href="/" className="btn btn-white mb-3">
                                                        <Loader className="icon-dual icon-xs mr-2"></Loader>
                                                        Load more..
                                                    </a>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    <Col xl={4}>
                        <h4>Filters</h4>
                        <AvForm>
                            <Row>
                                <Col sm={12} className="align-self-center">
                                    <AvField
                                        name="item_issue_number"
                                        label="IIN Number"
                                        type="text"
                                        onChange={this.createFormHandler}
                                        
                                    />
                                </Col>
                                

                                <Col sm={12} className="align-self-center">
                                <Label for="customer_type">Consigner Type</Label>

                                <Select
                                name="customer_type"
                                className="react-select"
                                classNamePrefix="react-select"
                                onChange={this.handleCustomerTypeSelectChange}
                                options={customerTypes}></Select>
                                </Col>


                                <Col sm={12} className="align-self-center">
                                <AvField
                                        name="phone"
                                        label="phone"
                                        type="text"
                                        onChange={this.createFormHandler}
                                        
                                    />
                                </Col>

                                <Col sm={12} className="align-self-center">
                                <AvField
                                        name="address"
                                        label="Location"
                                        type="text"
                                        onChange={this.createFormHandler}
                                        
                                    />
                                </Col>

                        

                                <Col sm={12} className="align-self-center">
                                    <AvField
                                        name="customer"
                                        label="Consigner Name"
                                        type="text"
                                        onChange={this.createFormHandler}
                                        
                                    />
                                </Col>
                                
                  
                                <Col sm={12} className="align-self-center">
                                    <br></br>
                                <Label for="partId">Product/Part Name</Label>

                                <Select
                                        name="partId"
                                        onChange={this.handleProductPartSelectChange}
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        options={this.state.productPartData}></Select>
                        


                        <br></br>

                                </Col>


        
                              <Col sm={12} className="align-self-center">
                                    <AvField
                                        name="complaint_number"
                                        label="Complaint Number "
                                        type="text"
                                        onChange={this.createFormHandler}
                                        
                                    />
                                </Col>
                                <Col sm={12} className="align-self-center">
                                    <AvField
                                        name="serial_numbers"
                                        label="Serial Number "
                                        type="text"
                                        onChange={this.createFormHandler}
                                    />
                                </Col>
                                <Col sm={12} className="align-self-center">
                                    <AvField
                                        name="remarks"
                                        label="Remarks"
                                        type="text"
                                        onChange={this.createFormHandler}
                                    />
                                </Col>

                            


                        <Col sm={12} className="align-self-center">
                        <Label for="inovice date"> IIN Profile between the dates</Label>

                        <Flatpickr value={this.state.filterDate}
                                                required={true}
                                                            onChange={(date,str) => {
                                                                let dates=str.split("to");
                                                                console.log(dates)

                                                                if((dates)&&dates.length==2){
                                                                let inputParams={...this.state.searchForm};
                                                                inputParams['opening_date']=dates[0].trim();
                                                                inputParams['closing_date']=dates[1].trim();
                                                                this.setState({ filterDate: str,searchForm:inputParams }) }}
                                                            }
                                                                options={{ 
                                                                  
                                                                    dateFormat:"d-m-Y",
                                                                    mode: "range" }}
                                                            className="form-control" />
                                            
</Col>
                                <Col sm={12} className="align-self-center">
                                <FormGroup>
        <Button color="primary" type="submit"
        onClick={()=>{this.reloadData();}}
        >
            Search
        </Button>
    </FormGroup>
                                </Col>
                            </Row>
                        </AvForm>
                    </Col>


                </Row>
            </React.Fragment>
        );
    }
}

export default IINList;
