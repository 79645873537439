import Moment from 'moment';

const toFullDate=(dateObj)=>{
Moment.locale('en');
return(Moment(dateObj).format("dddd, MMMM Do YYYY, h:mm:ss a"));
};

const isNow=(dateObj)=>{
    let currentDate= Moment().format('DD/M/YYYY')
    let givenDate=Moment(dateObj).format('DD/M/YYYY')
    return (givenDate === currentDate)
    };

    const isAgo=(dateObj,days)=>{

       var today = new Date();
       var day = new Date();
        day.setDate(today.getDate()-days);
        let currentDate= Moment(day).format('DD/M/YYYY')
        let givenDate=Moment(dateObj).format('DD/M/YYYY')
        return (givenDate === currentDate)
        };
        const getCurrentDate=()=>{
            let currentDate= Moment().format('DD/M/YYYY')
            return currentDate;

        }

        const convertDate=(dateObj)=>{
            let givenDate=Moment(dateObj).format('YYYY-MM-DD')
            return givenDate;

        }
        const convert=(t)=> 
{       let timeObj=""
        if(t==false)
        {
            timeObj=Moment().utc().format('DD-MM-YYYY HH:mm:ss');

        }
        else
        {
            timeObj=Moment(t,'DD-MM-YYYY').utc().format('DD-MM-YYYY HH:mm:ss');
        }
        return timeObj;

}
export  {toFullDate,isNow,isAgo,getCurrentDate,convertDate,convert};