import DataService from './DataService';

class ProductService extends DataService
{
 constructor()
 {
     super('product');
 }

}
export default new ProductService()