import { fetchData } from '../helpers/apiConnect';
import { defaultHeader ,formHeader} from '../helpers/globalConstants';

const MODULE_DETAILS={
    productCategory:{url:'/product_categories',name:'category_name'},
    productPartCategory:{url:'/product_part_categories',name:'category_name'},
    productPart:{url:'/product_parts',name:'part_name'},
    product:{url:'/products',name:'product_name'},
    checkList:{url:'/check_list',name:'list_tittle'},
    customer:{url:'/customers',name:'name'},
    department:{url:'/departments',name:'name'},
    ritn:{url:'/ritn_profile',name:'ritn'},
    iin:{url:'/iin_profile',name:'iin'},

    ritn_check_list:{url:'/ritn_check_list',name:'list_tittle'},
    staff:{url:'/staffs',name:'name'},
    task_note:{url:'/task_note',name:'name'}

    


}

class DataService {
    data=[];
   
    constructor(moduleString) {
       
        this.moduleString=moduleString
        this.module=MODULE_DETAILS[this.moduleString]
        console.log(this.module)
        this.moduleUrl = this.module.url;
        this.auth = true;
    }
    /*
    1. Create an item with 
    */
   setValue=valueObj=>
   {
   localStorage.setItem(valueObj.name,valueObj.value);
   };
  /*
    1. Create an item with 
    */
   getValue=name=>
   {
   localStorage.getItem(name);
   };
    createData(inputParms, header = defaultHeader,url=this.moduleUrl) {
        try {
            let options = {};
         
            options.url = url;
            
            options.method = 'post';
            options.header = header;
            options.auth = this.auth;
            options.params = inputParms;
            console.log(options);
            return new Promise((resolve, reject) => {
                // wrap in timeout to simulate server api call

                fetchData(options).then(
                    (response,data) => {
                        resolve(response); // Success!
                    },
                    (error) => {
                        console.log('reason', error); // Error!
                        reject('Server error');
                    }
                );
            });
        } catch (error) {
            alert(error);
        }
    }







    createDataFromForm(inputParms, header = formHeader,url=this.moduleUrl) {
        return this.createData(inputParms,header,url);
        
    }

    updateDataFromForm(inputParms,id, header = formHeader,url=this.moduleUrl) {
        return this.updateData(inputParms,id,header,url);
        
    }
    updateData(inputParms,id, header = defaultHeader,url=this.moduleUrl) {
        try {
            let options = {};
            options.url = url+"/"+id;
            options.method = 'put';
            options.header = header;
            options.auth = this.auth;
            options.params = inputParms;
            console.log(options);
            return new Promise((resolve, reject) => {
                // wrap in timeout to simulate server api call

                fetchData(options).then(
                    (response,data) => {
                        resolve(response); // Success!
                    },
                    (error) => {
                        console.log('reason', error); // Error!
                        reject('Server error');
                    }
                );
            });
        } catch (error) {
            alert(error);
        }
    }
     
 getAbstractData(inputParams)
 {

    this.getData(inputParams).then((response) => {
    let abstractData=response.data.data.map(obj =>({label:obj[this.module.name],value:obj.id}));
    return abstractData;
});
}

  /*
    1. Create an item with 
    */

   uploadExcel(inputParms, header=formHeader) {
    try {
        let options = {};
        options.url = this.moduleUrl+'/upload_excel';
        options.method = 'post';
        options.header = header;
        options.auth = this.auth;
        options.params = inputParms;
        console.log(options);
        return new Promise((resolve, reject) => {
            // wrap in timeout to simulate server api call

            fetchData(options).then(
                (response) => {
                    resolve(response); // Success!
                },
                (error) => {
                    console.log('reason', error); // Error!
                    reject('Server error');
                }
            );
        });
    } catch (error) {
        alert(error);
    }
}











    /*
    2. Get items 
    */
    getData(inputParms, header = defaultHeader,url=this.moduleUrl) {
        try {
            let options = {};
            options.url = url;
            options.method = 'get';
            options.header = header;
            options.auth = this.auth;
            options.params = inputParms;
            return new Promise((resolve, reject) => {
                // wrap in timeout to simulate server api call

                fetchData(options).then(
                    (response) => {
                        resolve(response); // Success!
                    },
                    (error) => {
                        reject('Server error');
                    }
                );
            });
        } catch (error) {
            alert(error);
        }
    }


    

    /*
    2. Get items 
    */
    findData(inputParms, header = defaultHeader,url=this.moduleUrl) {
        try {
            let options = {};
            options.url = url;
            options.method = 'post';
            options.header = header;
            options.auth = this.auth;
            options.params = inputParms;
            console.log(options);

            return new Promise((resolve, reject) => {
                // wrap in timeout to simulate server api call

                fetchData(options).then(
                    (response) => {
                        resolve(response); // Success!
                    },
                    (error) => {
                        console.log('reason', error); // Error!
                        reject('Server error');
                    }
                );
            });
        } catch (error) {
            alert(error);
        }
    }
    /*
    2. Get items 
    */
   getIndividualData(id,inputParms, header = defaultHeader,url=this.moduleUrl) {
    try {
        let options = {};
        options.url = url+"/"+id;
        options.method = 'get';
        options.header = header;
        options.auth = this.auth;
        options.params = inputParms;
        return new Promise((resolve, reject) => {
            // wrap in timeout to simulate server api call

            fetchData(options).then(
                (response) => {
                    resolve(response); // Success!
                },
                (error) => {
                    console.log('reason', error); // Error!
                    reject('Server error');
                }
            );
        });
    } catch (error) {
        alert(error);
    }
}
 /*
    2. Get items 
    */
   deleteData(inputParms, header = defaultHeader) {
    try {
        let options = {};
        options.url = this.moduleUrl+"/"+inputParms['id'];
        options.method = 'delete';
        options.header = header;
        options.auth = this.auth;
        options.params = {};
        return new Promise((resolve, reject) => {
            // wrap in timeout to simulate server api call

            fetchData(options).then(
                (response) => {
                    resolve(response); // Success!
                },
                (error) => {
                    console.log('reason', error); // Error!
                    reject('Server error');
                }
            );
        });
    } catch (error) {
        alert(error);
    }
}

}


export default DataService;
