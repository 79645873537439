import React from 'react';
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';
import * as FeatherIcon from 'react-feather';

import { isUserAuthenticated, getLoggedInUser } from '../helpers/authUtils';
import EditRITNForm from '../pages/apps/Return/Edit';
import IssueList from '../pages/apps/Issue/List';

// auth
const Login = React.lazy(() => import('../pages/auth/Login'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
const Register = React.lazy(() => import('../pages/auth/Register'));
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'));
const Confirm = React.lazy(() => import('../pages/auth/Confirm'));
// dashboard
const Dashboard = React.lazy(() => import('../pages/dashboard'));

// apps


const ProjectDetail = React.lazy(() => import('../pages/apps/Project/Detail/'));
const TaskList = React.lazy(() => import('../pages/apps/Tasks/List'));
const TaskBoard = React.lazy(() => import('../pages/apps/Tasks/Board'));

// pages

const Error404 = React.lazy(() => import('../pages/other/Error404'));
const Error500 = React.lazy(() => import('../pages/other/Error500'));

//Settings
const Company  =  React.lazy(() => import('../pages/settings/Company'));
const Module  =  React.lazy(() => import('../pages/settings/Modules'));
const ProductCheckList  =  React.lazy(() => import('../pages/settings/ProductCheckList'));
const PartsCheckList  =  React.lazy(() => import('../pages/settings/PartsCheckList'));
const RitnCheckList  =  React.lazy(() => import('../pages/settings/RitnCheckList'));
const CheckListSettings  =  React.lazy(() => import('../pages/settings/CheckList/'));

//staffs
const Roles  =  React.lazy(() => import('../pages/staffs/Roles/'));
const Staffs  =  React.lazy(() => import('../pages/staffs/Staffs/'));
const StaffProfile = React.lazy(() => import('../pages/staffs/Profile/'));
const UserLog=React.lazy(() => import('../pages/staffs/UserLog/'));
//customers
const Customers  =  React.lazy(() => import('../pages/customers/Customers/'));
const CustomerProfile = React.lazy(() => import('../pages/customers/Profile/'));
//Products
const Products =React.lazy(() => import('../pages/products/Products/'));
const Catogery =React.lazy(() => import('../pages/products/Catogery/'));
const Parts =React.lazy(() => import('../pages/products/Parts/'));
const PartsCatogery =React.lazy(() => import('../pages/products/PartsCatogery/'));

//return List
const ReturnList = React.lazy(() => import('../pages/apps/Return/List'));
const ReturnBoard = React.lazy(() => import('../pages/apps/Return/Board'));
const NewReturn = React.lazy(() => import('../pages/apps/Return/New'));
const PerfomanceChart=React.lazy(() => import('../pages/reports'));
const ReturnProductReport=React.lazy(() => import('../pages/reports/ret_prod_report'));
const ReturnProfile=React.lazy(() => import('../pages/apps/Return/Detail/'));
const EditReturn=React.lazy(() => import('../pages/apps/Return/Edit'));
const EditIssue=React.lazy(() => import('../pages/apps/Issue/Edit'));

const NewIssue = React.lazy(() => import('../pages/apps/Issue/New'));






// handle auth and authorization
const PrivateRoute = ({ component: Component, roles, ...rest }) => (
    <Route
        {...rest}
        render={props => {
            if (!isUserAuthenticated()) {
                // not logged in so redirect to login page with the return url
                return <Redirect to={{ pathname: '/account/login', state: { from: props.location } }} />;
            }

            const loggedInUser = getLoggedInUser();
            // check if route is restricted by role
            if (roles && roles.indexOf(loggedInUser.role) === -1) {
                // role not authorised so redirect to home page
                return <Redirect to={{ pathname: '/' }} />;
            }

            // authorised so return component
            return <Component {...props} />;
        }}
    />
);

// root routes
const rootRoute = {
    path: '/',
    exact: true,
    component: () => <Redirect to="/dashboard" />,
    route: PrivateRoute,

};
const staffProfile= {
    path: '/staffs/staffprofile',
    exact: true,
    component: StaffProfile,
    route: PrivateRoute,
};
const userLog={
    path: '/staffs/userlog',
    exact: true,
    component: UserLog,
    route: PrivateRoute,
};
const customerProfile= {
    path: '/consignors/consignorprofile',
    exact: true,
    component: CustomerProfile,
    route: PrivateRoute,
};
const editReturn= {
    path: '/return/edit',
    exact: true,
    component: EditReturn,
    route: PrivateRoute,
};
const editIssue= {
    path: '/issue/edit',
    exact: true,
    component: EditIssue,
    route: PrivateRoute,
};


const returnProfile= {
    path: '/return/returnprofile',
    exact: true,
    component: ReturnProfile,
    route: PrivateRoute,
};

// dashboards
const dashboardRoutes = {
    path: '/dashboard',
    name: 'Dashboard',
    icon: FeatherIcon.Home,
    header: 'Navigation',
    component: Dashboard,
    route: PrivateRoute
};

// apps

const customerAppRoutes = {
    path: '/apps/consignors',
    name: 'Consignors',
    header: 'Modules',
    icon: FeatherIcon.User,
    badge: {
        variant: 'success',
        text: '',
    },
    component: Customers,
    route: PrivateRoute,
   
};



const staffAppRoutes = {
    path: '/apps/staffs',
    name: 'Staff',
    icon: FeatherIcon.UserPlus,
    roles: ['Admin','QM'],

    children: [
        {
            path: '/staff/staffs',
            name: 'Staffs',
            component: Staffs,
            badge: {
                variant: 'info',
                text: '',
            },
            route: PrivateRoute,
            roles: ['Admin','QM'],
        },
        {
            path: '/staff/userlog',
            name: 'UserLog',
            component: UserLog,
            badge: {
                variant: 'info',
                text: '',
            },
            route: PrivateRoute,
            roles: ['Admin','QM'],
        },
       /* {
            path: '/apps/projects/detail',
            name: 'Team',
            component: ProjectDetail,
            route: PrivateRoute,
            roles: ['Admin'],
        },
        {
            path: '/staff/roles',
            name: 'Roles',
            component: Roles,
            route: PrivateRoute,
            roles: ['Admin'],
        },*/
    ]
};

const storeppRoutes = {
    path: '/apps/return',
    name: 'Service/Sales Return',
    icon: FeatherIcon.Bookmark,
    children: [
        {
            path: '/apps/return/new',
            name: ' New Return',
            component: NewReturn,
            route: PrivateRoute,
            roles: ['Admin','Receipt'],
        },
        {
            path: '/apps/return/list',
            name: 'Return List',
            component: ReturnList,
            route: PrivateRoute,
        },
        {
            path: '/apps/return/board',
            name: 'Return Monitor',
            component: ReturnBoard,
            route: PrivateRoute,
        },
        {
            path: '/apps/return/report',
            name: 'Perfomance Report',
            component: PerfomanceChart,
            route: PrivateRoute,
            roles: ['Admin','Inspection','QM'],
        },
        {
            path: '/apps/return/return_report',
            name: 'Return Parts Report',
            component: ReturnProductReport,
            route: PrivateRoute,
            roles: ['Admin','Inspection','QM'],
        }    ],
};
const spareappRoutes = {
    path: '/apps/return',
    name: 'Spare Issued',
    icon: FeatherIcon.Bookmark,
    children: [
       
        {
            path: '/apps/issue/New',
            name: ' New Issued',
            component: NewIssue,
            route: PrivateRoute,
            roles: ['Admin','Accounting/Settlement'],
        },
        {
            path: '/apps/issue/List',
            name: 'Issued Items',
            component: IssueList,
            route: PrivateRoute,
        }
    ],
};

const productppRoutes = {
    path: '/apps/products',
    name: 'Products',
    icon: FeatherIcon.Bookmark,
    children: [
        {
            path: '/product/products',
            name: 'Product List',
            component: Products,
            route: PrivateRoute,
            badge: {
                variant: 'warning',
                text: '',
            },

        },
        {
            path: '/product/catogery',
            name: 'Product Category List',
            component: Catogery,
            badge: {
                variant: 'danger',
                text: '',
            },
            route: PrivateRoute,
          
        },
        {
            path: '/product/parts',
            name: 'Parts List',
            component: Parts,
            route: PrivateRoute,
           
        },
        {
            path: '/product/partscatogery',
            name: ' Part Category List',
            component: PartsCatogery,
            route: PrivateRoute,
            
        },

    ],
};

const appRoutes = [storeppRoutes,spareappRoutes, staffAppRoutes, productppRoutes,customerAppRoutes];



// pages
const settingsRoutes = {
    path: '/pages',
    name: 'RITN Settings',
    header: 'Settings',
    icon: FeatherIcon.Settings,
    roles: ['Admin','FM','QM'],

    children: [
        
        {
            path: '/settings/checklist',
            name: 'Manage CheckList',
            component: CheckListSettings,
            route: PrivateRoute,
            roles: ['Admin','FM','QM'],
            
        },

    ]
};


// components
const componentsRoutes = {
    path: '/ui',
    name: 'Admin Setings',
    header: 'Admin',
    icon: FeatherIcon.Package,
    children: [
        {
            path: '/pages/modules',
            name: 'Modules',
            component: Module,
            route: PrivateRoute,
            roles: ['Admin'],
        },


    ]
};


// auth
const authRoutes = {
    path: '/account',
    name: 'Auth',
    children: [
        {
            path: '/account/login',
            name: 'Login',
            component: Login,
            route: Route,
        },
        {
            path: '/account/logout',
            name: 'Logout',
            component: Logout,
            route: Route,
        },
        {
            path: '/account/register',
            name: 'Register',
            component: Register,
            route: Route,
        },
        {
            path: '/account/confirm',
            name: 'Confirm',
            component: Confirm,
            route: Route,
        },
        {
            path: '/account/forget-password',
            name: 'Forget Password',
            component: ForgetPassword,
            route: Route,
        },
    ],
};

// flatten the list of all nested routes
const flattenRoutes = routes => {
    let flatRoutes = [];

    routes = routes || [];
    routes.forEach(item => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

// All routes
const allRoutes = [
    rootRoute,
    staffProfile,
    customerProfile,
    editReturn,
    editIssue,
    returnProfile,
    dashboardRoutes,
    ...appRoutes,
    settingsRoutes,
    //componentsRoutes,
    authRoutes,
];

const authProtectedRoutes = [dashboardRoutes, ...appRoutes, settingsRoutes, 
   /* componentsRoutes,*/
];
const allFlattenRoutes = flattenRoutes(allRoutes);
export { allRoutes, authProtectedRoutes, allFlattenRoutes };
