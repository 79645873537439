import {getLoggedInUser } from './authUtils';

/*
Permissions array will store permission key of Permission object
*/


const RolePermisions={
'Accounting/Settlement':[
    4,6,7,15,9,12,10
],
'FM':[9,10,11,7
],
'Inspection':[3,2,4]
,"Manager":[],
'Others':[],
'QM':[8,11],
'Receipt':[1,4,12,14]
}



const PERMISSIONS={
 
    "edit_part_recieved_date":1,
    'edit_part_recieved_inspection_date':2,
    "new_return":14,

    "change_sales_return":3,
    "change_return":4,
    "delete_return":5, 
    "change_conclusion":6,
    "change_issue":7,
    'change_staff':8,
    "change_product":9,
    "change_customer":10,
    "change_settings":11
    ,
    'change_drop_return_issue': 13,

    'change_spare_issue': 15,

    

};
const RoleWiseOptions={
    "issueProductPartLabel":{ 
        "QM":['product_name','part_name'],
        "Accounting/Settlement":['product_code','part_code'],
        "Admin":['product_name','part_name'],
        'FM':['product_name','part_name'],
        'Inspection':['product_name','part_name']
        ,"Manager":['product_name','part_name'],
        'Others':['product_name','part_name'],
        'Receipt':['product_name','part_name']
},
    "checkListOptions":{
        "QM":[{label:"Inspection",value:15}],
        "FM":[  {label:"Receipt",value:16},
                {label:"Accounting/Settlement",value:14}],
        "Admin":[{ label:"Inspection",value:15},
                {label:"Receipt",value:16}
              , {label:"Accounting/Settlement",value:14}]},

    "issueDropdownOptions":{
        "Accounting/Settlement":[

            { value: 'Pending', label: 'Pending' },
            { value: 'Returned', label: 'Returned'},
        
            { value: 'Dropped', label: 'Dropped',disabled: true },
            { value: 'Non Returnable(Item)', label: 'Non Returnable',disabled: true },
        ],
        "FM":[

            { value: 'Pending', label: 'Pending' },
            { value: 'Returned', label: 'Returned'},
        
            { value: 'Dropped', label: 'Dropped' },
            { value: 'Non Returnable(Item)', label: 'Non Returnable'},
        ],
        "Admin":[

            { value: 'Pending', label: 'Pending' },
            { value: 'Returned', label: 'Returned'},
        
            { value: 'Dropped', label: 'Dropped'},
            { value: 'Non Returnable(Item)', label: 'Non Returnable'},
        ],
        "QM": [

            { value: 'Pending', label: 'Pending',disabled: true  },
            { value: 'Returned', label: 'Returned',disabled: true },
        
            { value: 'Dropped', label: 'Dropped',disabled: true },
            { value: 'Non Returnable(Item)', label: 'Non Returnable',disabled: true },
        ]
    ,
    "Others": [

        { value: 'Pending', label: 'Pending',disabled: true  },
        { value: 'Returned', label: 'Returned',disabled: true },
    
        { value: 'Dropped', label: 'Dropped',disabled: true },
        { value: 'Non Returnable(Item)', label: 'Non Returnable',disabled: true },
    ]
    
    }

    
}

const issueStatus = [

    { value: 'Pending', label: 'Pending' },
    { value: 'Returned', label: 'Returned'},

    { value: 'Dropped', label: 'Dropped',disabled: true },
    { value: 'Non Returnable(Item)', label: 'Non Returnable',disabled: true },
];
const getRoleWiseOptions=(keyModule)=>{
    let user=getLoggedInUser();
    if(RoleWiseOptions[keyModule][user.role])
    {
        return RoleWiseOptions[keyModule][user.role]

    }else
    {
        return [];
    }

 
}
const getAvailablePermissions=()=>{
 return PERMISSIONS;   
}
/*
check all permissions 
para@permissionRequiredArray : Requied permission to paass this menu
para@permissionList : all available permssion of current user
*/
const checkArrayPermission=(permissionRequiredArray,permissionList)=>
{
    let user=getLoggedInUser();
    if(user.role=='Admin')
    {
return true;
    }
    let PERMISSIONS=getAvailablePermissions()
    
    let resultPermission=0;
    permissionRequiredArray.map((currentValue)=>
    {
        if((!currentValue in PERMISSIONS)||(permissionList.indexOf(PERMISSIONS[currentValue])!=-1 ))
        {
            resultPermission=resultPermission+1;
        }

    });
    //let resultPermission = permissionRequiredArray.reduce((total, currentValue) => 

    //total = total +((!currentValue in permissionList)|| (permissionList.indexOf(PERMISSIONS[currentValue])==-1 )),0);

    return resultPermission;
}
const applyPermissionMenuFilter=(menuRoutes)=>
{
   let userRole=getLoggedInUser();
   userRole=userRole.role;

    menuRoutes=menuRoutes.filter((routeObj)=>{
      
    if((routeObj.children)&&(routeObj.children.length>0)){
        routeObj.children=applyPermissionMenuFilter(routeObj.children);
    }
    return( (routeObj.roles)?
    (routeObj.roles.indexOf(userRole)!=-1):true);

    });

    return menuRoutes;

}
/*
returns required permission for this project
*/
const getPermissionDic=(permissionsRequired)=>
{
    let permissionsRequiredDic={};
    for (var key in permissionsRequired) {
        //if given key is single string check single, if more check all permissions
        permissionsRequired[key]=
        (typeof(permissionsRequired[key])=="string")?isPermitted(permissionsRequired[key]):isAllPermitted(permissionsRequired[key])

    }
    return permissionsRequired;
   
}

/*

*/
const isAllPermitted=(permissionsRequired)=>{
    let user=getLoggedInUser();
    
    if(user.role=='Admin')
    {
return true;
    }
    let permissions=getAllPermissions();
   return (checkArrayPermission(permissionsRequired,permissions)>0)
}


const isPermitted=(permissionRequired)=>{
    let user=getLoggedInUser();
    if(user.role){
    if(user.role=='Admin')
    {
return true;
    }
    let permissionList=RolePermisions[user.role];
    if(permissionList.indexOf(PERMISSIONS[permissionRequired])!=-1)
    {
        return true;
    }
}
    return false;
}

const addTwoArrays=(array1,array2)=>{
// Merges both arrays and gets unique items
let  array3 = array1.concat(array2); 
return array3;
}

const setSelectedBranches=valueObj=>
{
 if(valueObj){
 let branchString=JSON.stringify(valueObj);
 localStorage.setItem('branches',branchString);
}

}


const getSelectedBranches=valueObj=>
{
    let branchString=localStorage.getItem('branches');
    let branches=(branchString==null)?[]:JSON.parse(branchString);
    return branches;
}
const getAllPermissions=()=>
{


    let permissionArray=[]

    let user=getLoggedInUser();

    user.roles.map((roleObj)=>{
   

            permissionArray=[...permissionArray,...roleObj.roles.permissions];

        
    });

    return permissionArray;
}


export { getAvailablePermissions,getRoleWiseOptions,isPermitted,isAllPermitted, 
    applyPermissionMenuFilter,checkArrayPermission,getAllPermissions};
