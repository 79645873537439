import DataService from './DataService';

class StaffService extends DataService
{
 constructor()
 {
     super('staff');
 }

}
export default new StaffService()