
import {baseURL,defaultHeader} from './globalConstants';
import axios from 'axios';
const getToken=()=>{
    return localStorage.getItem('AUTH_TOKEN');

}

const getUser=()=>{
    return localStorage.getItem('user');

}

const getAxioInstance =(apiUrl=baseURL,options={})=>{ 

    //create API instance
   
    return axios.create({
    baseURL:apiUrl,
    headers:{...defaultHeader}
})};




const fetchData=(options={},apiUrl=baseURL)=>{
    console.log(getToken());
     let headerValue = options.hasOwnProperty('header') ? options.header :defaultHeader
     if((options.hasOwnProperty('auth'))&&(options.auth===true))
     {
         headerValue['Authorization']='Bearer '+getToken();
     }
     let config = {
         method: options.method,
         url: baseURL+ options.url,
         headers: headerValue,
     }
     if(options.params){
     if((options.method=='post')||(options.method=='POST')||(options.method=='put')||(options.method=='PUT'))
     {
         config['data']=options.params;
 
     }
     if(options.method=='get')
     {
 config['params']=options.params;
     }
     }
     console.log(config);
     let user={};
     return new Promise((resolve, reject) => {
         // wrap in timeout to simulate server api call
         setTimeout(() => {
                     axios(config).then(response => {
                     resolve(response); // Success!
                   }, error => {
                     console.log('reason',error); // Error!
                     reject("UserName or password is not valid ");
 
                   });            
                 
                
                     
 
                 
 
             },10
      
         )});
     
     }
     
 
    






/*

const fetchData=(options={},apiUrl=baseURL)=>{
   
    let headerValue = options.hasOwnProperty('header') ? options.header :defaultHeader
    if((options.hasOwnProperty('auth'))&&(options.auth===true))
    {
        headerValue['Authorization']='Bearer '+getToken();
    }
    const config = {
        method: options.method,
        url: baseURL+ options.url,
        headers: headerValue,
        data:options.params
    }
    
    console.log(config);
    let user={};
    return new Promise((resolve, reject) => {
        // wrap in timeout to simulate server api call
        setTimeout(() => {
                    axios(config).then(response => {
                    resolve(response); // Success!
                  }, error => {
                    console.log('reason',error); // Error!
                    reject("UserName or password is not valid ");

                  });            
                
               
                    

                

            },10
     
        )});
    
    }
    
*/
    const loginData=(options={},apiUrl=baseURL)=>{
   
        let headerValue = options.hasOwnProperty('header') ? options.header :defaultHeader
        if((options.hasOwnProperty('auth'))&&(options.auth===true))
        {
            headerValue['Authorization']='Bearer '+getToken();
        }
    
        const config = {
            method: options.method,
            url: baseURL+ options.url,
            headers: headerValue,
            data:options.params
        }
        console.log(config);
        let user={};
        return new Promise((resolve, reject) => {
            // wrap in timeout to simulate server api call
            setTimeout(() => {
                        axios(config).then(response => {

                        resolve(response); // Success!
                      }, error => {
                        console.log('reason',error); // Error!
                        reject("UserName or password is not valid ");
    
                      });            
                    
                   
                        
    
                    
    
                },10
         
            )});
        
        }
        


export  { fetchData,loginData };