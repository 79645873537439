import React ,{ useState } from 'react';
import { UncontrolledCollapse, Button, CardBody, Card } from 'reactstrap';
import { Link } from 'react-router-dom';
import TimeLine from './TimeLine';
import Badge from 'reactstrap/lib/Badge';
import { getLoggedInUser } from '../../../../helpers/authUtils';
const isInAssigned=(assignedList)=>{
  if(!assignedList)
  {
    return true;
  }

  let user=getLoggedInUser();
  let role=user.role;
  let status=false
  if((role=='Admin')||(assignedList.length==0))
  {
    return true;
  }
  else{
    status= ((assignedList.map((roleObj)=>roleObj.name)).indexOf(role)!=-1);
  }
return status;
}
const CheckListCard = (props) => { 
  const [checkListObj, setcheckListObj] = useState(props.checkListObj);
  



   let compid="toggler"+checkListObj.id;
   let hash_compid="#toggler"+checkListObj.id;

   return (
<React.Fragment>
       
{(!checkListObj.status) && <span
                                className='badge badge-danger badge-pill'                                    
                            
                                >Pending</span>},{(checkListObj.status)&& <span
                                className='badge badge-success badge-pill'                                    
                            
                                >Closed
                                
                                
                                </span>},
                                
                   
                                
                                
                                
                                 <li data-toggle="tab" id={compid} >CID-{checkListObj.check_list.id} : {checkListObj.check_list.list_tittle}
      
      ,

                                 
                                
                                {[( typeof(checkListObj.check_list.assigned_roles)!='undefined')&&                               
                                checkListObj.check_list.assigned_roles.map((depItem, idx2) => {

                                  return( 
                                  <Badge color="warning" pill className="mr-1" id="ritn">
                                  {depItem.name}
                              </Badge>)
                                  
                                  
                             
                                }),]},

                        

                        <ul className="list-inline font-13 text-sm-right">
                        {checkListObj.published_date&&<li className="list-inline-item pr-1">
                                <i className="uil uil-schedule font-16 mr-1"></i>
                                updated at {checkListObj.published_date}

                                <i className="uil uil-schedule font-16 mr-1"></i>

                            </li>}
                            {checkListObj.created_by&&<li className="list-inline-item pr-1">
                                <i className="uil uil-align-alt font-16 mr-1"></i>
                                added By  {checkListObj.created_by}

                            </li>}
                            <li className="list-inline-item"></li>
                        </ul>
                 
      <Link className="uil uil-angle-down font-size-18 align-middle">
     
          
          </Link></li>
{(isInAssigned(checkListObj.check_list.assigned_roles))&&
    <UncontrolledCollapse toggler={hash_compid}>
      <Card>
        <CardBody>
        {(typeof(checkListObj)!='undefined') && <TimeLine checkListObj={checkListObj}
                                    reloadRITNObject={props.reloadRITNObject}
                                    staffData={props.staffData}

        
        setcheckListObj={setcheckListObj}
        />}
        </CardBody>
      </Card>
    </UncontrolledCollapse>
}
  </React.Fragment>
   );
};


export default CheckListCard;