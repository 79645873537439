import DataService from './DataService';
import { defaultHeader ,formHeader} from '../helpers/globalConstants';

class RITNCheckListService extends DataService
{
 constructor()
 {
     super('ritn_check_list');
 }

createNote(id,inputParms, header = defaultHeader)
{
    try{
        let url=this.moduleUrl+"/"+id+'/create_note'
return this.createDataFromForm(inputParms,header,url);
    }

        catch (error) {
            alert(error);
        }

}

updateNote(id,inputParms, header = defaultHeader)
{
    try{
        let url="/task_note";
return this.updateDataFromForm(inputParms,id,header,url);
    }

        catch (error) {
            alert(error);
        }

}

}
export default new RITNCheckListService()