import DataService from './DataService';
import { defaultHeader ,formHeader} from '../helpers/globalConstants';

class IINService extends DataService
{
 constructor()
 {
     super('iin');
 }
 generatetIINnumber(inputParms, header = defaultHeader)
{
    try{
        let url=this.moduleUrl+"/generate_iin_number";
return this.getData(inputParms,header,url);
    }

        catch (error) {
            alert(error);
        }

}







}


export default new IINService()