const baseURL=window.apiEnd;//'https://antcolonywebworks.pythonanywhere.com/' //'https://antcolonywebworks.pythonanywhere.com/';
const currentURL=window.currentUrl;//"https://sepackstore.leadstracer.in/";
//const baseURL=process.env.REACT_APP_BASE_URL;
//const currentURL=process.env.REACT_APP_CURRENT_URL;
//var currentURL="http://localhost:3000/";
//const baseURL="http://127.0.0.1:8000";

console.log("pasth",baseURL)
const departmentData=[{label:"Store",value:4},
{label:"Despatch",value:5},
{label:"Quality",value:3},
{label:"Finance",value:1},
{label:"Sales",value:2},
{label:"Service",value:6}];

const roleData=[
{label:"Accounting/Settlement",value:14},
{label:"Inspection",value:15},
{label:"Receipt",value:16},
{label:"QM",value:13},
{label:"FM",value:6},
{label:"Others",value:12},

];



const  returnThroughOptions = [
    { value: 'Courier', label: 'Courier' },
    { value: 'Direct', label: 'Direct' },
    { value: 'Post', label: 'Post' },
];
const partStatus=[
    { value: 'Active', label: 'Active' ,className:'primary'},
    { value: 'Trail/Test', label: 'Trail/Test', className:'secondary'},
    { value: 'Discontinued', label: 'Discontinued',className:'danger' }
];
const conclusionData = [

    { value: 'Pending', label: 'Pending' },
    { value: 'Closed', label: 'Closed'},
    { value: 'Repaired All Items', label: 'Repaired All Items' },
    { value: 'Dumped', label: 'Non Returnable',disabled: true },

];

const issueStatus = [

    { value: 'Pending', label: 'Pending' },
    { value: 'Returned', label: 'Returned'},

    { value: 'Dropped', label: 'Dropped',disabled: true },
    { value: 'Non Returnable(Item)', label: 'Non Returnable',disabled: true },
];
 
  const customerTypes = 
  [

    { value: 'Dealer/Asc', label: 'Dealer/Asc' },
    { value: 'CFA', label: 'CFA' },
    { value: 'Not Available', label: 'Not Available' },
    { value: 'Staff (ST/SE/SIC)', label: 'Staff(ST/SE/SIC)' },
    { value: 'Customer', label: 'Customer' },
];








const placeLocations = 

[
{value:"Andhra Pradesh",label:"Andhra Pradesh"}
,{value:"Andaman and Nicobar Islands",label:"Andaman and Nicobar Islands"}
,{value:"Arunachal Pradesh",label:"Arunachal Pradesh"}
,{value:"Assam",label:"Assam"}
,{value:"Bihar",label:"Bihar"}
,{value:"Chandigarh",label:"Chandigarh"}
,{value:"Chhattisgarh",label:"Chhattisgarh"}
,{value:"Dadar and Nagar Haveli",label:"Dadar and Nagar Haveli"}
,{value:"Daman and Diu",label:"Daman and Diu"}
,{value:"Delhi",label:"Delhi"}
,{value:"Lakshadweep",label:"Lakshadweep"}
,{value:"Puducherry",label:"Puducherry"}
,{value:"Goa",label:"Goa"}
,{value:"Gujarat",label:"Gujarat"}
,{value:"Haryana",label:"Haryana"}
,{value:"Himachal Pradesh",label:"Himachal Pradesh"}
,{value:"Jammu and Kashmir",label:"Jammu and Kashmir"}
,{value:"Jharkhand",label:"Jharkhand"}
,{value:"Karnataka",label:"Karnataka"}
,{value:"Kerala",label:"Kerala"}
,{value:"Madhya Pradesh",label:"Madhya Pradesh"}
,{value:"Maharashtra",label:"Maharashtra"}
,{value:"Manipur",label:"Manipur"}
,{value:"Meghalaya",label:"Meghalaya"}
,{value:"Mizoram",label:"Mizoram"}
,{value:"Nagaland",label:"Nagaland"}
,{value:"Odisha",label:"Odisha"}
,{value:"Punjab",label:"Punjab"}
,{value:"Rajasthan",label:"Rajasthan"}
,{value:"Sikkim",label:"Sikkim"}
,{value:"Tamil Nadu",label:"Tamil Nadu"}
,{value:"Telangana",label:"Telangana"}
,{value:"Tripura",label:"Tripura"}
,{value:"Uttar Pradesh",label:"Uttar Pradesh"}
,{value:"Uttarakhand",label:"Uttarakhand"}
,{value:"West Bengal",label:"West Bengal"}
]



const defaultHeader= {
    'content-type':'application/json',
    };
const formHeader= {
    'content-type': 'multipart/form-data'
};
    export {baseURL,partStatus,issueStatus,currentURL,
        returnThroughOptions,placeLocations,conclusionData,
        customerTypes,defaultHeader,formHeader,
        departmentData,roleData}