import React, { useState } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import img1 from '../../../../assets/images/small/img-1.jpg'
import img2 from '../../../../assets/images/small/img-2.jpg';
import img3 from '../../../../assets/images/small/img-3.jpg';
import avatarImg2 from '../../../../assets/images/users/avatar-9.jpg';
import { 
    
    
    Row,
    Col,
    Card,
    Button,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    ButtonGroup,
    CustomInput,
    FormGroup,
    Label,
    Media,
    Badge,
    UncontrolledButtonDropdown,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    UncontrolledTooltip} from 'reactstrap';
import TaskCard from './TaskCard';
import { toFullDate,isNow,isAgo } from '../../../../services/DataFormatHandler';
import {
    alertCreation,
    alertUpload,
    alertDeletion,
    alertUpdation,
    alertError,
    confirmDeleteAction,
    confirmAction,
} from '../../../../components/Alerts';
import RITNCheckListService from '../../../../services/RITNCheckListService';


const NoteLog = (props) => {

    const taskStatusHandler = (event) => {
        let inputForm={}
        if (event.target.type == 'checkbox') {
            let key = event.target.name;
            let value = event.target.checked;
            inputForm['is_task_closed']=value;
            props.updateNote(props.note.id, inputForm);
        }
    };

  let  note=props.note;
  let checkStatusId="cstatus"+note.id;
console.log(note.images);
    return <div className="pb-4">
 {/*Main thread and photos*/}

        <Media body>{note.is_task &&

        <i class="fas fa-file">Task</i>}{!note.is_task &&

            <i class="fas fa-file">Note</i>}
            
                    <h6 className="mt-0 mb-0 font-size-15 font-weight-normal">

                        <a href="/" className="font-weight-bold">{note.title}</a> {note.description}<span className="font-weight-bold text-primary">{note.created_by}</span>
                    </h6>
                    <p className="text-muted">{toFullDate(note.published_date)}</p>
                    <p className="text-muted">created By <b>{note.created_staff_name}</b></p>

        </Media>
        <Media body>
        {note.is_task &&   <FormGroup>
                    <Label for="inovice date">Task Status : {note.is_task && note.is_task_closed &&
            
            <h6> <span class="badge badge-success">Closed</span></h6>}
            {note.is_task &&(!note.is_task_closed )&&
            <h6> <span class="badge badge-danger">Pending</span></h6>}</Label>

                    <CustomInput type="switch" id={checkStatusId} name="task_status"
                    onChange={taskStatusHandler}
                    checked={props.note.is_task_closed}

                    />
                </FormGroup>}
                <p className="text-muted">assigned to <b>{note.assigned_staff_name}</b></p>

 <div className="date">
                                    <UncontrolledButtonDropdown className="float-left mt-2">
                                        <DropdownToggle
                                            tag="button"
                                            className="btn btn-link p-0 dropdown-toggle arrow-none">
                                            <i className="uil uil-ellipsis-v text-muted font-size-12"></i>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem onClick={() =>alert(note.id)}>Edit</DropdownItem>
                                            <DropdownItem
                                                onClick={() => {
                                                    alert(note.id);
                                                }}>
                                                Delete
                                            </DropdownItem>
                                            <DropdownItem>

                                          
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                </div>
     </Media>
        ,
        <hr></hr>
        <div className="album">
            <Row>
                <Col sm={6}>
                <Carousel showThumbs={false}>
                {(typeof(note.images)!='undefined')&&(note.images.length>0)&&
note.images.map((img)=>{
    return (<div><img src={img.image} />
                    </div>)
                    
                })}
                    
                   
                   
                   

                </Carousel>
                </Col>
            </Row>
        </div>
        <hr></hr>
    </div>
}

const NoteBord=(props)=>
{




    if((typeof(props.checkListObj.notes)!='undefined') && (props.checkListObj.notes.length>0))
    {
    var sorted_notes =props.NoteList[props.noteFilter]
    

}
    return (
        <React.Fragment>

<div className="left-timeline mt-3 mb-3 pl-4">
    {(typeof(props.checkListObj.notes)!='undefined') && (props.checkListObj.notes.length>0)&&
                <ul className="list-unstyled events mb-0">
                    {sorted_notes.map((item, idx) => {
                        return <li className="event-list" key={idx}>
                            <NoteLog note={item} 
                                        updateNote={props.updateNote}

                            
                            />
                        </li>
                    })}
                </ul>}
            </div>

        </React.Fragment>);
}

const TimeLine = (props) => {
    const[editFormVisiblity,setEditFormVisiblity]=useState(true)
    const[noteObj,setNoteObj]=useState({id:0,title:"",description:"",
    is_task:false,is_task_closed:false,assigned_departments:[],
    task_priorities:1,
    images:[]})
    const ALL=0,NOTE=1,TASK=2,TASK_PENDING=3,TASK_CLOSED=4;
    const [noteFilter,setNoteFilter]=useState(ALL)
    var NoteList={0:[],1:[],2:[],3:[],4:[]}

    if((typeof(props.checkListObj.notes)!='undefined') && (props.checkListObj.notes.length>0))
    {
    var sorted_notes =[... props.checkListObj.notes]
    sorted_notes=sorted_notes.sort((a,b) => {
        return new Date(a.published_date).getTime() - 
            new Date(b.published_date).getTime()
    }).reverse();

    NoteList[ALL]=sorted_notes;
    NoteList[NOTE]= sorted_notes.filter((noteObj) =>{return (!noteObj.is_task)});
    NoteList[TASK]= sorted_notes.filter((noteObj) =>{return (noteObj.is_task)});
    NoteList[TASK_PENDING]= sorted_notes.filter((noteObj) =>{return ((noteObj.is_task)&&(!noteObj.is_task_closed))});
    NoteList[TASK_CLOSED]= sorted_notes.filter((noteObj) =>{return ((noteObj.is_task)&&(noteObj.is_task_closed))});
    }
  

    
    const updateCheckListStatus = (id, status) => {
        let inputParams = { status: status };
        RITNCheckListService.updateData(inputParams, id)
            .then((response) => {
                if (response.data['status'] === 'Sucess') {
                    let checkListObj = response.data.data;
                    props.setcheckListObj(checkListObj);
                    setEditFormVisiblity(!editFormVisiblity);

                    //props.reloadRITNObject();
                } else {
                    alertError(response.data['message']);
                }
            })
            .catch((err) => alert(err));
    };


    const addNote = (noteInputForm) => {
        let formData = new FormData(); //formdata object
        if (noteInputForm['note_images']) {
            for (let i = 0; i < noteInputForm.note_images.length; i++) {
                formData.append('note_images', noteInputForm.note_images[i]);
            }
            delete noteInputForm.note_images;
        }
        Object.keys(noteInputForm).forEach(function (key) {
            formData.append(key, noteInputForm[key]); //append the values with key, value pair
        });
        RITNCheckListService.createNote(props.checkListObj.id, formData)
            .then((response) => {
                updateCheckListStatus(props.checkListObj.id, props.checkListObj.status);
            })
            .catch((err) => alert(err));
    };
    
    const updateNote = (id,noteInputForm) => {
        console.log(noteInputForm);
        let formData = new FormData(); //formdata object
        if (noteInputForm['note_images']) {
            for (let i = 0; i < noteInputForm.note_images.length; i++) {
                formData.append('note_images', noteInputForm.note_images[i]);
            }
            delete noteInputForm.note_images;
        }
        Object.keys(noteInputForm).forEach(function (key) {
            formData.append(key, noteInputForm[key]); //append the values with key, value pair
        });
        RITNCheckListService.updateNote(id, formData)
            .then((response) => {
                updateCheckListStatus(props.checkListObj.id, props.checkListObj.status);
                setEditFormVisiblity(!editFormVisiblity);

            })
            .catch((err) => alert(err));
    };

    return (
        <React.Fragment>
            {(typeof(props.checkListObj)!='undefined')&&<TaskCard 
            checkListObj={props.checkListObj} 
            setcheckListObj={props.setcheckListObj}
            reloadRITNObject={props.reloadRITNObject}
            setNoteFilter={setNoteFilter}
            allCount={NoteList[ALL].length}
            noteCount={NoteList[NOTE].length}
            taskCount={NoteList[TASK].length}
            taskPendingCount={NoteList[TASK_PENDING].length}
            taskClosedCount={NoteList[TASK_CLOSED].length}
            updateCheckListStatus={updateCheckListStatus}
            addNote={addNote}            
            setEditFormVisiblity={setEditFormVisiblity}
            editFormVisiblity={editFormVisiblity}
            setNoteObj={setNoteObj}
            noteObj={noteObj}
            staffData={props.staffData}


            />}
            {
                //comented
                /*
            <h5 className="mt-3">Task/Notes</h5>
            {(typeof(props.checkListObj)!='undefined')&&<NoteBord checkListObj={props.checkListObj} 
            setcheckListObj={props.setcheckListObj}
            reloadRITNObject={props.reloadRITNObject}
            noteFilter={noteFilter}
            NoteList={NoteList}
            updateNote={updateNote}
            setNoteObj={setNoteObj}
            noteObj={noteObj}
            />}
            */
            }
          
          

        </React.Fragment>
    );
};

export default TimeLine;
