import { isPropsEqual } from '@fullcalendar/core';
import React from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';

import FileUploader from '../../../components/FileUploader';
import ProductPartService from '../../../services/ProductPartService';

const FileUpload = (props) => {
    return (
        <React.Fragment>


            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <h4 className="header-title mt-0 mb-1">Upload Documents</h4>


                            <FileUploader
                                onFileUpload={props.handleFiles}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default FileUpload;
