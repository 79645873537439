import DataService from './DataService';

class ProductPartService extends DataService
{
 constructor()
 {
     super('productPart');
 }

}
export default new ProductPartService()