import React, { useState } from "react";
import {
    Input,
    Button,Card,CardBody,
    Row,UncontrolledTooltip,
    Col,
} from 'reactstrap';


import Select from 'react-select';
import { isPermitted } from "../../../helpers/permission-manager";

function App(props) 
{
  return (
    <div className="App">
           
        <hr></hr>

      {
      
      props.productPartInput.map((x, i) => {
        let defaultProdValue,defaultCount=0,defaultMentionedCount=0,defaultRecivedCount=0,defaultGoodCount=0,defaultDefectCount=0,defaultItemsToBeChecking_numbers=0,
        defaultComplaintNumber="",defaultComment="",defaultSerialNumbers="";
      
if (typeof(x.value)!='undefined'){
  defaultProdValue=props.productPartData.filter(cobj => cobj.value === x.value) // set selected value
}

if(x){
  defaultMentionedCount=(x.mentioned_numbers)?x.mentioned_numbers:0; // set selected value
  defaultRecivedCount=(x.recieved_numbers)?x.recieved_numbers:0;
  defaultGoodCount=(x.good_item_numbers)?x.good_item_numbers:0;
  defaultDefectCount=(x.defect_item_numbers)?x.defect_item_numbers:0;
  defaultComment=x.comment;
  defaultComplaintNumber=x.complaint_number;
  defaultSerialNumbers=x.serial_numbers;
  defaultItemsToBeChecking_numbers=(x.items_to_be_checking_numbers)?x.items_to_be_checking_numbers:0;
}

        return (
<Card>
              <CardBody>
              <Row className="mt-1">
              <Col lg={1}><b>SI</b></Col>
              <Col lg={3}><b>Product/Part Name</b></Col>
              <Col lg={2}><b>QTY Mentioned</b></Col>
              <Col lg={2}><b>QTY Recived</b></Col>
              <Col lg={2}><b>Good Items</b></Col>
              <Col lg={2}><b>Defect Items</b></Col>
              
            </Row>


            <Row className="mt-1">
            <Col lg={1}><b>{i+1}</b></Col>

            <Col lg={3}>

            {(typeof(props.update)!='undefined')&&<Select
                                        name="partId"
                                        onChange={e => props.handleProductPartSelectChange(e, i)}
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        isDisabled={!isPermitted("change_sales_return")}
                                        value={defaultProdValue}
                                        options={props.productPartData}></Select>}
                                        {(typeof(props.update)=='undefined')&&<Select
                                        name="partId"
                                        onChange={
                                          (e )=> {console.log(e,i);props.handleProductPartSelectChange(e, i)}
                                        }
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        defaultValue={defaultProdValue}
                                        isDisabled={!isPermitted("change_sales_return")}

                                        options={props.productPartData}></Select>}


           
            </Col>
            <Col lg={2}>
            <Input
              name="mentioned_numbers"
              id="mentioned_numbers"
              type="number"
              min={0}
          
              readOnly={!isPermitted("change_sales_return")}

              max={5000}
              value={defaultMentionedCount}
              onChange={e => props.handleProductPartCountChange(e, i)}
              />
               
               <UncontrolledTooltip placement="top" target="mentioned_numbers">Nos of Mentioned Items</UncontrolledTooltip>

            </Col>
            <Col lg={2}>
            <Input
              name="recieved_numbers" id="recieved_numbers"
              type="number"
              min={0}
              readOnly={!isPermitted("change_sales_return")}

              max={500}

			        placeholder="Nos of QTY recived"
              value={defaultRecivedCount}
              onChange={e => props.handleProductPartCountChange(e, i)}

            />
            <UncontrolledTooltip placement="top" target="recieved_numbers">Nos of QTY recived</UncontrolledTooltip>
            </Col>
            <Col lg={2}>
            <Input
              name="good_item_numbers" id="good_item_numbers"
              type="number"

			        placeholder="Nos of Good Items"
              min={0}
              readOnly={!isPermitted("change_sales_return")}

              max={defaultRecivedCount}
              value={defaultGoodCount}
              onChange={e => props.handleProductPartCountChange(e, i)}

            />
            <UncontrolledTooltip placement="top" target="good_item_numbers">Nos of good Items</UncontrolledTooltip>
            </Col>
            <Col lg={2}>
            <Input
              name="defect_item_numbers" id="defect_item_numbers"
              type="number"
              min={0}
              readOnly={!isPermitted("change_sales_return")}

              max={defaultRecivedCount}
			        placeholder="Nos of  Defected Items"
              value={defaultDefectCount}
              onChange={e => props.handleProductPartCountChange(e, i)}

            />
            <UncontrolledTooltip placement="top" target="defect_item_numbers">Nos of Defect Items</UncontrolledTooltip>
            </Col>
            </Row>
            <Row>
            <Col lg={3}><b>Items To be Checked</b></Col>  
  
              <Col lg={3}><b>Complaint No</b></Col>  
              <Col lg={3}><b>ID/SI NO</b></Col> 
              <Col lg={3}><b>Remarks</b></Col> 
            </Row>
            <Row>
            <Col lg={3}>
            <Input
              name="items_to_be_checking_numbers"
              id="items_to_be_checking_numbers"
              type="number"
              min={0}
              readOnly={!isPermitted("change_sales_return")}

              max={defaultRecivedCount}
              value={defaultItemsToBeChecking_numbers}
              onChange={e => props.handleProductPartCountChange(e, i)}
              />
              </Col>
              <Col lg={3}>
            <Input
                  name="complaint_number"
                  value={defaultComplaintNumber}
                  type="textarea"
                  readOnly={!isPermitted("change_sales_return")}

                  placeholder=""
                  onChange={e => props.handleProductPartCountChange(e, i)}
                />
              </Col> 
              <Col lg={3}>
              <Input
                  name="serial_numbers"
                  value={defaultSerialNumbers}
                  type="textarea"
                  readOnly={!isPermitted("change_sales_return")}

                  placeholder=""
                  onChange={e => props.handleProductPartCountChange(e, i)}
                />
                </Col> 
            <Col lg={3}>
                <Input
                  name="comment"
                  value={defaultComment}
                  type="textarea"
                  readOnly={!isPermitted("change_sales_return")}

                  placeholder=""
                  onChange={e => props.handleProductPartCountChange(e, i)}
                />
              </Col>

            {isPermitted("change_sales_return")&&<Col lg={1}>
              { props.productPartInput.length >0 && <Button
                color="danger"
                onClick={() => {props.handleRemoveClick(i);
              }}>-</Button>}
              { props.productPartInput.length - 1 === i && <Button  className="ml-1" color="success" onClick={props.handleAddClick}>+</Button>}

            </Col>}
            
            
            
            </Row></CardBody></Card>
        );
      })}
      {/*<div style={{ marginTop: 20 }}>{JSON.stringify(inputList)}</div>*/}
    </div>
  );
}

export default App;