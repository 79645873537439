import DataService from './DataService';
import { defaultHeader ,formHeader} from '../helpers/globalConstants';

class RITNService extends DataService
{
 constructor()
 {
     super('ritn');
 }
 generatetRITNnumber(inputParms, header = defaultHeader)
{
    try{
        let url=this.moduleUrl+"/generate_ritn_number";
return this.getData(inputParms,header,url);
    }

        catch (error) {
            alert(error);
        }

}

getConclusions(inputParms, header = defaultHeader)
{
    try{
        let url=this.moduleUrl+"/get_conclusions";
return this.getData(inputParms,header,url);
    }

        catch (error) {
            alert(error);
        }

}


getPerfomanceReports(inputParms, header = defaultHeader)
{
    try{
        let url=this.moduleUrl+"/get_perfomance_reports";
return this.getData(inputParms,header,url);
    }

        catch (error) {
            alert(error);
        }

}
getReturnReports(inputParms, header = defaultHeader)
{
    try{
        let url=this.moduleUrl+"/get_ret_product_reports";
return this.getData(inputParms,header,url);
    }

        catch (error) {
            alert(error);
        }

}
getPendingReports(inputParms, header = defaultHeader)
{
    try{
        let url=this.moduleUrl+"/get_ritn_pending";
return this.getData(inputParms,header,url);
    }

        catch (error) {
            alert(error);
        }

}

}


export default new RITNService()