import DataService from './DataService';

class CustomerService extends DataService
{
 constructor()
 {
     super('customer');
 }

}
export default new CustomerService()